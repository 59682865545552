<template>
  <div class="cart">
    <div class="row">
      <div class="col-sm-3">
        <h1 class="h3">Preventivo</h1>
        Hai <strong>{{ cart.count }}</strong> prodotti nel carrello<br> per un totale di  <strong>&euro;&nbsp;{{ formatPrice(cart.total) }}</strong>
      </div>

      <div class="col-sm-9">
          <div :class="'alert alert-' + order_creation.status + ' mb-5 text-center alert-dismissible fade show'" v-if="order_creation.status">
            
            <h4>{{ order_creation.message }}</h4>
             <button type="button" class="btn-close" aria-label="Close" @click.prevent="order_creation.status = null"></button>
          </div>

        <div  v-if="current_customer">
          <h4 class=""><span class="badge badge-pill bg-dark">{{ current_customer.external_id }}</span> {{ current_customer.company_name }}</h4> 
          <span v-if="current_customer.price_list">  - Listino <strong>{{ current_customer.price_list.code }}</strong> </span>
          <span> - Pagamento <strong>{{ current_customer.payment_mode.description }}</strong></span>
        </div>
        <hr class="mb-2">



        <!-- SUMMARY CART START -->
        <div class="cart-summary mb-4" >
            
          <div v-if="cart.count > 0">

            <div class="row mb-3">
              <div class="col-sm-6">
                 <p v-if="cart.id"><small>Il preventivo corrente è salvato col nome <strong>{{ cart.name }}</strong> (ID: {{ cart.id }}) </small></p>
              </div>
              <div class="col-sm-6 text-end">
                <button class="btn btn-warning btn-sm me-2" @click="resetCart">Svuota Carrello</button>
                <button :disabled="current_state != 'additions'" class="btn btn-info btn-sm me-2" @click="cart_saved = false" data-bs-toggle="modal" data-bs-target="#cartSaveModal" v-html="enable_cart_reports ? 'Salva / Email / Pdf' : 'Salva Preventivo' "></button>
            
              </div>
            </div>
           
                
                
            <div class="table-responsive">
              <table class="table table-striped table-cart mb-3">
                <thead>
                  <tr>
                    <!-- <th>Riga</th>-->
                    <th>Codice</th>
                    <th>Descrizione</th>
                    <th>Note</th>
                    <th>Prezzo unitario</th>
                    <th>Quantità</th>
                    <th>Sconto</th>
                    <!--<th>Sconto 2 (%)</th> -->
                    <th>Totale</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item,idx in cart.items" v-bind:key="idx">
                    <!--<td>{{ idx + 1 }}</td>-->
                    <td><span :title="item.product_id" class="badge badge-pill bg-dark">{{ item.product_code }}</span></td>
                    <td><strong>{{ item.description }}</strong></td>
                    <td>
                        <button @click="current_item = item" class="btn btn-light" v-html=" 'Note' + ( item.notes ? '*' : '')"   data-bs-toggle="modal" data-bs-target="#cartItemNotesModal" :disabled="current_state != 'cart'">
                        </button>
                    </td>
                    <td>
                        <input class="form-control text-end" type="text" readonly :value="formatPrice(item.price)">
                    </td>
                    <td>
                        <input class="form-control text-end" type="number" min="1" v-model="item.quantity" @change="updateCart" :disabled="current_state != 'cart'">
                    </td>
                    <td>
                      <select :disabled="item.disable_discounts"  class="form-select text-end" v-model="item.discount_1" @change="setDiscount2(item)">
                          <option value="0">Nessuno sconto</option>
                          <option  v-for="i in 4" :key="i" :value="i">{{ i }} + {{ i }}</option>
                      </select>
                    </td>
                    <!--
                    <td>
                        <input class="form-control text-end" type="number" min="0" v-model="item.discount_1" @change="updateCart" :disabled="current_state != 'cart'">
                    </td>
                    <td>
                        <input class="form-control text-end" type="number" min="0" v-model="item.discount_2" @change="updateCart" :disabled="current_state != 'cart'">
                    </td>
                    -->
                    <td>
                        <input class="form-control text-end" type="text" :value="formatPrice(item.row_total)" readonly>
                    </td>
                    
                    <td><button  @click="removeCartItem( idx )" class="btn btn-warning" :disabled="current_state != 'cart'"><i class="bi bi-dash-circle"></i></button></td>
                    

                  </tr>


                  <tr v-if="cart.shipping_items" v-for="shipping_item in cart.shipping_items">
                    <!--<td></td>-->
                    <td><span :title="shipping_item.id" class="badge badge-pill bg-dark">{{ shipping_item.product_code }}</span></td>
                    <td><strong>{{ shipping_item.description }}</strong></td>
                    <td>
                        
                    </td>
                    <td>
                        <input class="form-control text-end" type="text" readonly :value="formatPrice(shipping_item.price)">
                    </td>
                    <td>
                        <input class="form-control text-end" type="number" readonly min="1" v-model="shipping_item.quantity">
                    </td>
                    <td>
                      &nbsp;
                    </td>
                   
                    <td>
                        <input class="form-control text-end" type="text" readonly :value="formatPrice(shipping_item.row_total)" >
                    </td>
                    
                    <td>
                      <!--
                      <button  @click="setShippingItem( null )" class="btn btn-warning" :disabled="current_state != 'cart'"><i class="bi bi-dash-circle"></i></button>
                      -->
                    </td>
                  </tr>


                  <tr v-if="cart.promotion_item">
                    <!--<td></td>-->
                    <td><span :title="cart.promotion_item.product_id" class="badge badge-pill bg-dark">{{ cart.promotion_item.product_code }}</span></td>
                    <td><strong>{{ cart.promotion_item.description }}</strong></td>
                    <td>
                        
                    </td>
                    <td>
                        <input class="form-control text-end" type="text" readonly :value="formatPrice(cart.promotion_item.price)">
                    </td>
                    <td>
                        <input class="form-control text-end" type="number" readonly min="1" v-model="cart.promotion_item.quantity">
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    
                    <td>
                        <input class="form-control text-end" type="text" readonly :value="formatPrice(cart.promotion_item.row_total)" >
                    </td>
                    
                    <td><button  @click="setPromotionItem( null )" class="btn btn-warning" :disabled="current_state != 'cart'"><i class="bi bi-dash-circle"></i></button></td>
                  </tr>



                  <tr v-if="cart.discount_percentage">
                    <!--<td></td>-->
                    <td>&nbsp;</td>
                    <td><strong>Sconto per modalità pagamento</strong></td>
                    <td>
                        
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        <input class="form-control text-end" type="text" readonly min="0" :value="cart.discount_percentage + '%'">
                    </td>
                    
                    <td>
                        <input class="form-control text-end" type="text" readonly :value="formatPrice( cart.discount_amount )" >
                    </td>
                    
                    <td>
                      <!--
                      <button  @click="setShippingItem( null )" class="btn btn-warning" :disabled="current_state != 'cart'"><i class="bi bi-dash-circle"></i></button>
                      -->
                    </td>
                  </tr>


                </tbody>
                <tfoot>
                  <tr>
                    <!--<td></td>-->
                    <td></td>
                    <td><strong>Totale</strong></td>
                    <td colspan="3"><small>Peso totale stimato: <strong>{{ cart.total_weight}}</strong> kg </small></td>
                    <td></td>
                    
                    <td><input class="form-control text-end" type="text" :value="formatPrice(cart.total_final)" readonly></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
              
            </div>

            <!-- ORDER NOTES | START -->

            <div class="card card-body">
              <h4 class="mb-3">Note all'ordine</h4>
              <textarea class="form-control" rows="3" v-model="cart.notes" @blur="updateCart" placeholder="Inserisci qui le note" :disabled="current_state != 'cart'"></textarea>
            </div>
            
            <!-- ORDER NOTES | END -->

            
          </div>
          <div class="alert alert-info " v-else>Il carrello è vuoto</div>
              
              
        </div>
        <!-- SUMMARY CART END -->


        <!-- CART EDITING | START -->
        <div  v-if="current_state == 'cart' && cart.items.length > 0">
          <div class="row">
              
              <div class="col-sm-12">
                <button class="btn btn-success btn-lg w-100 mb-3" @click.prevent="getCartAdditions(); current_state = 'additions';">Avanti</button>
              </div>
          </div>
        </div>
        <!-- CART EDITING | END -->

        <!-- ADDITIONS START -->
        <div v-if="current_state == 'additions'">
            
            <div class="shipping-address mb-3">
              <div class="card card-body">
                <h4 class="mb-3">Destinazione</h4>



                <div class="row mb-3">
                  <div class="col-6">
                    <div class="form-check">
                      <input type="radio" v-model="shipping_address_is_new" class="form-check-input" :value="false" >
                      <label class="form-check-label">Usa un indirizzo esistente</label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-check">
                      <input type="radio" v-model="shipping_address_is_new" class="form-check-input" :value="true" >
                      <label class="form-check-label">Specifica un nuovo indirizzo</label>
                    </div>
                  </div>
                </div>

                <div v-if="!shipping_address_is_new">
                  <select class="form-select" v-model="shipping_address" v-if="current_customer" >
                    <option :value="null">--</option>
                    <option :value="a" v-for="a, idx in current_customer.addresses" v-bind:key="idx" >{{ a.main ? 'FATTURAZIONE - ' : (a.name ? a.name + ' - ' : '') }} {{ a.address }} - {{ a.zip_code }} {{ a.city }} {{ a.district ?  '(' + a.district + ')' : '' }}</option>
                  </select>
                </div>

                <div v-if="shipping_address_is_new">
                  <textarea class="form-control" rows="10" v-model="shipping_address_new" ></textarea>
                </div>
              </div>

              
            </div>

            <div class="cart_additions mb-3">
              <div class="row row-eq-height">
                <div class="col-sm-6">
                  <div class="card card-body h-100 mb-4">
                    <h4 class="mb-4">Spese di spedizione</h4>

                    <!-- 
                    <div class="alert alert-info text-center" v-if="amount_to_free_shipping > 0">Per la spedizione gratuita ti mancano<br>  
                      <span class="h3">{{ formatPrice(amount_to_free_shipping) }}&euro;</span>
                    </div>
                    -->

                    <div class="alert alert-info text-center" v-if="free_shipping_message" v-html="free_shipping_message"></div>


                    <!--
                    <table class="table  table-cart-additions table-condensed">
                      <tbody>
                        <tr v-for="s in  cart_additions.shipping" :key="s.id">
                          <td>
                             <input class="form-check-input" type="radio" :value="s" v-model="current_shipping">
                          </td>
                          
                          <td>
                            <CatalogImage :code="s.external_id" :key="s.external_id" :imgclass="'img-product'"/>
                          </td>
                          <td><span :title="s.id" class="badge badge-pill bg-dark">{{ s.external_id }}</span></td>
                          <td>
                            {{ s.description }} - <strong>{{ formatPrice(s.price) }}&euro;</strong>
                          </td>
                        </tr>
                        <tr>
                          <td><input class="form-check-input" type="radio" :value="null" v-model="current_shipping"></td>
                          <td></td>
                          <td></td>
                          <td>Non inserire spese di spedizione</td>
                        </tr>
                      </tbody>
                    </table>
                    -->
                    <table class="table  table-cart-additions table-condensed">
                      <tbody>
                        <tr v-for="s in  cart_additions.shipping" :key="s.id">
                          
                          
                          <td>
                            <CatalogImage :code="s.external_id" :key="s.external_id" :imgclass="'img-product'"/>
                          </td>
                          <td><span :title="s.id" class="badge badge-pill bg-dark">{{ s.external_id }}</span></td>
                          <td>
                            {{ s.description }} - <strong>{{ formatPrice(s.price) }}&euro;</strong>
                          </td>
                        </tr>
                        
                      </tbody>
                    </table>
                    
                    <!-- <button @click="setShippingItem(current_shipping)" class="btn btn-primary my-3">Aggiungi al carrello</button> -->
                  </div>
                </div>



                <div class="col-sm-6">
                  <div class="card card-body h-100 mb-4" v-if="cart_additions && cart_additions.promotion && cart_additions.promotion.length > 0">
                    <h4 class="mb-4">Promozioni disponibili</h4>
                    <table class="table table-cart-additions table-condensed">
                      <tbody>
                        <tr v-for="s in  cart_additions.promotion" :key="s.id">
                          <td>
                             <input class="form-check-input" type="radio" :value="s" v-model="current_promotion">
                          </td>
                          <td>
                            <CatalogImage :code="s.external_id" :key="s.external_id" :imgclass="'img-product'"/>
                          </td>
                          <td><span :title="s.id" class="badge badge-pill bg-dark">{{ s.external_id }}</span></td>
                          <td>
                            {{ s.description }}
                          </td>
                        </tr>
                        <tr>
                          <td><input class="form-check-input" type="radio" :value="null" v-model="current_promotion"></td>
                          <td></td>
                          <td></td>
                          <td>Non inserire promozioni</td>
                        </tr>
                      </tbody>
                    </table>
                    <button @click="setPromotionItem(current_promotion)" class="btn btn-primary my-3">Aggiungi al carrello</button>
                  </div>
                </div>
              </div>
            </div>


            

            

            <div class="row">
              <div class="col-4">
                <button class="btn btn-secondary btn-lg w-100 mb-3" @click="current_state = 'cart'">Indietro</button>
              </div>
              <div class="col-8">
                <button class="btn btn-success btn-lg w-100 mb-3" @click="createOrder">Invia ordine</button>
              </div>
            </div>
            
        </div>
        <!-- ADDITIONS END -->



        
      </div>
    </div>


    <!-- CART ITEM NOTES MODAL | START -->
    <div class="modal fade" tabindex="-1" id="cartItemNotesModal">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Note al prodotto</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="" v-if="current_item">
                <div class="my-4">
                  <span class="badge badge-pill bg-dark">{{ current_item.product_code }}</span> {{ current_item.description }}
                </div>

                <div class="form-group">
                  
                  <textarea class="form-control" rows v-model="current_item.notes" ></textarea>
                </div>
                <div class="text-end">
                  <button class="btn btn-sm" @click="current_item.notes = ''">Svuota note</button>
                </div>
              </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="current_customer = null" data-bs-dismiss="modal">Chiudi</button>
            </div>
            </div>
        </div>
    </div>
    <!-- CART ITEM NOTES MODAL | END -->


    <!-- CART SAVE MODAL | START -->
    <div class="modal fade" tabindex="-1" id="cartSaveModal">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Salva Preventivo</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="">

               
                <div class="form-group">
                  <label>Nome del preventivo</label>
                  <div class="input-group">
                    <input type="text" class="form-control" v-model="cart.name">
                    <button type="button" class="btn btn-primary" :disabled="!cart.name"  @click="saveCart()">Salva</button>
                  </div>
                </div>
                  
                <div class="alert alert-info p-1 small my-4 ">Dopo aver salvato il preventivo, è possibile inviarlo via email al cliente, oppure scaricarne la versione in PDF.</div>


                <div class="row mt-4">
                  <div class="col-sm-8">
                    <label>Invia per email a</label>
                    <div class="input-group">
                      <input type="text" class="form-control form-control-sm" v-model="send_customer_email_address">
                      <button type="button" class="btn btn-primary" :disabled="!cart_saved"  @click="sendCartEmail()">Invia</button>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <label>Scarica il </label>
                    <a :class="'btn btn-primary w-100 ' + ( !cart_saved ? 'disabled' : '') "  :href="pdfUrl" target="_blank">PDF</a>
                  </div>
                </div>


                
              </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
               
            </div>
            </div>
        </div>
    </div>
    <!-- CART SAVE MODAL | END -->
    
    
  </div>
</template>

<script>
// @ is an alias to /src

import CmMixin from '../mixins/CmMixin'
import CatalogImage from '../components/CatalogImage'

export default {
  mixins: [ CmMixin ],
  components: { CatalogImage },
  name: 'Cart',
  data: () => {
		return {
            current_state: 'cart',

            current_item: null,

            shipping_address: null,
            shipping_address_is_new: false,
            shipping_address_new: '',

            cart_additions: {},

            // current_shipping: null,
            current_promotion: null,

            
            send_customer_email_address: '',


            //amount_to_free_shipping: 0,
            free_shipping_message: null,
            order_creation: {
                status : null,
                message : '',
            },

            cart_saved: false,

		}
	},
  mounted: function(){

    // valorizzo l'indirizzo di spedizione: se mi arriva dal carrello, altrimenti prendo il principale del cliente

    if( this.cart.shipping_address )
      this.shipping_address = this.cart.shipping_address;
    else if( this.current_customer && this.current_customer.addresses != undefined ){

      this.shipping_address = this.current_customer.addresses.find( function( item ){
        return item.main;
      });
      
      this.setShippingAddress();
    }

    if( this.cart.shipping_address_is_new )
      this.shipping_address_is_new = this.cart.shipping_address_is_new;

    if( this.cart.shipping_address_new )
      this.shipping_address_new = this.cart.shipping_address_new;

    

    if( this.currentCustomerEmail )
      this.send_customer_email_address = this.currentCustomerEmail;

    if( this.current_customer.payment_mode.discount )
      this.$store.commit('setCartDiscount', this.current_customer.payment_mode.discount);
    
  },
  methods:{
    resetCart: function( force ){
      if( force || this.confirm('Sei sicuro di voler rimuovere tutti i prodotti dal carrello?') ){
        this.current_state = 'cart';
        
        this.shipping_address_is_new = false;
        this.shipping_address = null;
        this.shipping_address_new = null;

        this.cart_additions = {};

        // this.current_shipping = null;
        this.current_promotion = null;
        

        
        this.send_customer_email_address = '';

        //this.amount_to_free_shipping = 0;
        this.free_shipping_message = null;
  
        this. order_creation = {
              status : null,
              message : '',
        };

        this.cart_saved = true;
        
        this.$store.commit('resetCart');
      }
    },
    
    saveCart: function(){
      var self = this;

      self.cart_saved = true;

      var post_data = {
        cart: this.cart,
        
      }
      this.api.post( 'cart/save' , post_data)
      .then(function (response) {
          if(response.data.cart){
            self.$store.commit('setCart', response.data.cart);
            self.notification.success( 'Carrello salvato correttamente' );
            
          }
      })
    },

    sendCartEmail: function(type){
      var self = this;

      var post_data = {
        cart: this.cart,
        customer_email: this.send_customer_email_address,
        
      }

      this.api.post( 'cart/sendEmail' , post_data)
      .then(function (response) {
          if(response.data.success == 1){
            
            self.notification.success( response.data.message ? response.data.message : 'Operazione eseguita correttamente' );
            
          }
      })
    },

    

    getCartAdditions: function(){

      var self = this;

      var post_data = {
        customer_external_id: this.current_customer.external_id,
        price_list_id: this.current_customer.price_list_id,
        total: this.cart.total,
        total_weight: this.cart.total_weight,
        items: this.cart.items,
      };

      this.api.post( 'cart/getAdditions' , post_data)
      .then(function (response) {

        if(response.data.cart_additions){
          self.cart_additions = response.data.cart_additions;

          console.log(  self.cart_additions.shipping );

          if( self.cart_additions.shipping ){
            // self.current_shipping = self.cart_additions.shipping[0];

            var shipping_items = [];

            for( var i in self.cart_additions.shipping){
              var item = self.cart_additions.shipping[i];

              var shipping_cart_item = {
                product_id: item.id,
                product_code: item.external_id,
                description: item.description,
                price: item.price,
                quantity: item.quantity,
                discount_1: 0,
                discount_2: 0,
                row_total: item.price * item.quantity,
                notes: ''
            };

              shipping_items.push( shipping_cart_item );
            }

            
            self.setCartShippingItems(shipping_items);

            /*
            if( response.data.free_shipping_addition && (response.data.free_shipping_addition.id != self.cart_additions.promotion[0])) {
              self.amount_to_free_shipping = response.data.free_shipping_addition.amount_min - parseFloat(self.cart.total);
            }
            */

            if( response.data.free_shipping_message != undefined ) {
              self.free_shipping_message = response.data.free_shipping_message;
            }


          }
            

          if( self.cart_additions.promotion[0] ){
            self.current_promotion = self.cart_additions.promotion[0];

          }
            
        }
          
      })

    },

    setShippingItem: function( item ){

      if( !item && !confirm('Rimuovere dal carrello le spese di spedizione?'))
        return;

      var shipping_cart_item = null;

      if( item ){
        var shipping_cart_item = {
            product_id: item.id,
            product_code: item.external_id,
            description: item.description,
            price: item.price,
            quantity: 1,
            discount_1: 0,
            discount_2: 0,
            row_total: item.price,
            notes: ''
        };
      }

      

      this.setCartShippingItem(shipping_cart_item);
    },


    setPromotionItem: function( item ){

      if( !item && !confirm('Rimuovere dal carrello la promozione?'))
        return;

      var promotion_cart_item = null;

      if (item){
        promotion_cart_item = {
            product_id: item.id,
            product_code: item.external_id,
            description: item.description,
            price: 0,
            quantity: 1,
            discount_1: 0,
            discount_2: 0,
            row_total: 0,
            notes: ''
        };
      }
      

      this.setCartPromotionItem(promotion_cart_item);
    },


    setShippingAddress: _.debounce(function(){

      var shipping_address = this.shipping_address
      var shipping_address_new = '';

      if( this.shipping_address_is_new ){ // Nuovo indirizzo

        // se è un indirizzo nuovo, specifico comunque quello di fatturazione
        
        shipping_address = this.current_customer.addresses.find( function( item ){
          return item.main;
        });
        if( !shipping_address )
          shipping_address = {}

        shipping_address_new = this.shipping_address_new;
        
      }
      else{
        var shipping_address = this.shipping_address
        var shipping_address_new = '';
      }

      // console.log('setShippingAddress', this.shipping_address_is_new, shipping_address, shipping_address_new);

      this.setCartShippingAddress( this.shipping_address_is_new, shipping_address, shipping_address_new);
    }, 400),

    createOrder: function(){

      if( !this.confirm('Procedere con l\'invio dell\'ordine?') )
        return;

      

      var self = this;

      this.api.post( 'cart/createOrder' , this.cart)
      .then(function (response) {
          if(response.data.success){
            self.current_state = 'cart';
            self.resetCart(true);
            var message = response.data.message ? response.data.message : 'Ordine inserito correttamente';
            self.order_creation.status = 'success';
            self.order_creation.message = message;
            self.notification.success( message );
          }
          else{
            self.current_state = 'cart';
            var message = 'Errore nell\'inserimento dell\'ordine. ' + response.data.message;
            self.order_creation.status = 'danger';
            self.order_creation.message = message;
            self.notification.error( message );
          }
      })
    },


    setDiscount2: function( item ){
      item.discount_2 = item.discount_1;
      this.updateCart();
    }
  },

  computed: {
    currentCustomerEmail: function(){
      if( this.current_customer.contacts)
      {
        var emails = this.current_customer.contacts.filter( function(item){
          return (item.type == 'email');
        }).sort( function( a, b ){
          return (b.main - a.main);
        } )

        return (emails.length > 0 ? emails[0].value : null);


      }
    },

    pdfUrl: function(){
      return this.api_url + '/cart/createPdf/' + this.cart.id;
    }
  },

  watch: {
    shipping_address_is_new: function(){
      this.setShippingAddress();
    },
    shipping_address: function(){
      this.setShippingAddress();
    },
    shipping_address_new: function(){
      this.setShippingAddress();
    },
  }

  
}
</script>

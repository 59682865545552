<template>
  <div class="product-categories" v-if="userHasRole('admin')">
    
    <h3>Categorie di prodotti</h3>

    <!-- TABELLA -->
    <div>
      
      
      <table class="table" v-if="product_categories.length > 0">
        <thead>
          <tr>
            <th scope="col">Codice</th>
            <th scope="col">Nome</th>
            <th scope="col" class="text-center">Abilitato</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="pc in product_categories" v-bind:key="pc.id">
            
            <td><span class="badge rounded-pill bg-primary">{{ pc.code }}</span></td>
            <td><strong>{{ pc.description }}</strong></td>
            <td class="text-center">
              <input type="checkbox" v-model="pc.enabled" @change="updateProductCategory(pc)">
            </td>
            
          </tr>
        </tbody>
      </table>
    </div>
    <!-- TABELLA / FINE -->
    
  </div>
</template>

<script>

import CmMixin from '../mixins/CmMixin'

export default {
  mixins: [ CmMixin ],
  data: () => {
		return {
      product_categories: [],

		}
	},
  mounted: function(){
    this.getProductCategories();
  },
  methods:{
     getProductCategories: function(){
       var self = this;
       this.api.get( 'product_categories')
          .then(function (response) {
            self.product_categories = response.data.product_categories;
              
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
     },
     updateProductCategory: function(product_category){
       var self = this;
       this.api.post( 'product_categories/update', { product_category: product_category })
          .then(function (response) {
            self.notification.success( 'Categoria aggiornata correttamente' );
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
     }
  },
}
</script>
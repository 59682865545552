<template>
  <div class="invoices">
    <div v-if="user && user.id">

    
    

      <div>
        <div class="row">
          <div class="col-sm-3">

            <h1 class="h3">Fatture</h1>            

            <!-- SIDEBAR AGENT | START -->
            <div v-if="user.role == 'agent'">

              <ul class="list-group" v-if="user.profiles.length > 0">

                <li :class="'list-group-item py-1 ' + ( selected_profile.id == undefined ? 'bg-primary' : '')"  >
                  <strong><a href="#" :class="( selected_profile.id == undefined ? 'text-white' : '')" @click.prevent="current_invoice = null; selected_profile = {}">Tutti i codici agente</a></strong>
                </li>

                <li :class="'list-group-item ' + (profile.id == selected_profile.id ? 'active' : '')" v-for="profile in user.profiles" v-bind:key="profile.id">
                  <strong><a href="#" :class=" (profile.id == selected_profile.id ? 'text-light' : '')" @click.prevent="current_invoice = null; selected_profile = profile; search= ''; current_invoice = null;">{{ profile.external_id }}</a></strong><br>
                  <small v-if="profile.sales_areas.length > 0">Zone: <strong>{{ profile.sales_areas.map( function( item ){ return item.code; } ).join(' - ') }}</strong></small>
                </li>
              </ul>
            </div>
            <!-- SIDEBAR AGENT | END -->


            <!-- SIDEBAR ADMIN | START -->
            <div v-if="user.role == 'admin'">
              <ul class="list-group" v-if="admin_users.length > 0">
                <li class="list-group-item bg-light">
                  <strong><small>Generale</small></strong>
                </li>
                <li :class="'list-group-item py-1 ' + ( selected_profile.id == undefined ? 'bg-primary' : '')"  >
                  <strong><a href="#" :class="( selected_profile.id == undefined ? 'text-white' : '')" @click.prevent="current_invoice = null; selected_profile = {}">Tutti gli agenti</a></strong>
                </li>
                <template  v-for="user in admin_users"  :key="'u'+user.id">
                  <li class="list-group-item bg-light">
                  <strong><small>{{ user.name }}</small></strong>
                  </li>
                  <template v-if=" user.profiles.length > 0">
                    <li v-for="profile in user.profiles" :class="'list-group-item py-1 ' + ( selected_profile.id == profile.id ? 'bg-primary' : '')"  :key="profile.id">
                      <strong><a href="#" :class="( selected_profile.id == profile.id ? 'text-white' : '')" @click.prevent="current_invoice = null; selected_profile = profile">{{ profile.external_id }}</a></strong>
                    </li>
                  </template>
                </template>
                
              </ul>
              
            </div>
            <!-- SIDEBAR ADMIN | END -->



          </div>
          <div class="col-sm-9">


            <!-- INVOICES LIST | START-->
            <div v-if="!current_invoice">

              <div v-if="invoices.data">
                <div class="row mb-4">
                  <div class="col-sm-2">
                    <p class="small">
                      <strong>{{ invoices.total }}</strong> fatture trovate
                      <template  v-if="invoices.total > 0"><br>Pagina <strong>{{ invoices.current_page }}</strong> di <strong>{{ invoices.last_page}}</strong></template>
                    </p>
                  </div>
                  <div class="col-sm-10 d-flex">

                    <!-- FILTERS | START -->
                    
                    <div class="form-group flex-fill pe-2">
                      <label class="small">A partire dal</label>
                      <input type="date" class="form-control form-control-sm" v-model="date_start" placeholder="Da">
                    </div>
                    <div class="form-group flex-fill pe-2">
                      <label class="small">Fino al</label>
                      <input type="date" class="form-control form-control-sm" v-model="date_end" placeholder="A">
                    </div>
                    <div class="form-group flex-fill pe-2">
                      <label class="small">Ricerca libera</label>
                      <input v-model="search" type="text" class="form-control form-control-sm" placeholder="Ragione sociale, partita IVA, etc" v-on:keyup.enter="getProfileInvoices({ search: search })">
                     
                    </div>
                    <button class="btn btn-primary" @click.prevent="getProfileInvoices({ search: search, status_id: current_status, date_start: date_start, date_end: date_end })">Cerca</button>
                    
                    
                    <!-- FILTERS | END -->
                    <hr>
                  </div>
                </div>
                
                <div class="table-responsive">
                  <table class="table table-striped table-hover" v-if="invoices.data && invoices.data.length > 0">
                    <thead>
                      <tr>
                        <th>Codice Fattura</th>
                        <th>Numero Fattura</th>
                        <th>Codice Cliente</th>
                        <th>Cliente</th>
                        
                        <th>Codice Agente</th>
                        <th>Data Emissione</th>


                        <th>Importo</th>
                        <th>Modalità di pagamento</th>
                        <!-- <th>Fatturato</th> -->
                        <th>Azioni</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="invoice in invoices_filtered" v-bind:key="invoice.key">
                        <td class="text-center"><span class="badge badge-pill bg-secondary">{{ invoice.code }}</span></td>
                        <td class="text-center"><span class="badge badge-pill bg-primary">{{ invoice.number }}</span></td>
                        <td class="text-center">
                          <ProfileBadge :customer="invoice.customer"/>
                        </td>
                        <td>{{ invoice.customer.company_name }}</td>
                        <td class="text-center"><span class="badge badge-pill bg-dark">{{ invoice.profile_external_id }}</span></td>
                        <td><small>{{ invoice.issue_date }}</small></td>

                        <td class="text-end">
                          <span class=" badge badge-pill badge-price bg-dark" v-if="invoice.total">
                            {{ formatPrice(invoice.total) }} &euro;
                          </span>
                        </td>
                        <td><small>{{  invoice.payment_mode ? invoice.payment_mode.description : '' }}</small></td>
                        
                        <td></td>
                        
                        <td>
                          <div class="d-flex justify-content-end">
                            <a v-if="invoice.pdf_available" target="_blank" :href="getPdfUrl(invoice.id)" class="me-2 btn btn-secondary"><i class="bi bi-file-pdf"></i></a>

                            <button @click="current_invoice = invoice; getCurrentInvoiceItems(); " class="btn btn-primary"><i class="bi bi-card-checklist"></i>
                            </button>
                          </div>
                          
                        </td>
                      </tr>
                    </tbody>

                  </table>
                </div>
          

                <div class=" row my-5"  v-if="invoices.total > 0">

                  <div class="col-6 col-sm-6">
                    
                    <div class="form-group">
                      <label for="">Totale</label>
                      <input type="text " class="form-control text-end" readonly v-model="invoices_total_net">
                    </div>
                   
                  </div>

                    
                    
                  
                  <div class="paginator col-sm-6 text-right">
                    <br>
                    <div class="input-group">

                      <button class="btn btn-outline-secondary" :disabled="invoices.current_page == 1" @click.prevent="getProfileInvoices({ page: 1, search: search, date_start: date_start, date_end: date_end })" type="button"><i class="bi-chevron-double-left" ></i></button>
                      
                      <button class="btn btn-outline-secondary" :disabled="invoices.current_page <= 1" @click.prevent="getProfileInvoices({ page: invoices.current_page - 1, search: search, date_start: date_start, date_end: date_end })" type="button"><i class="bi-chevron-left" ></i></button>
                      
                      <select v-model="invoices.current_page" class="form-select text-center border-secondary" @change="getProfileInvoices({ page: invoices.current_page, search: search, date_start: date_start, date_end: date_end })">
                        <option v-for="p in parseInt(invoices.last_page)" :key="p" :value="p">{{ p }}</option>
                      </select>
                      
                      <button class="btn btn-outline-secondary" :disabled="invoices.current_page >= invoices.last_page"  @click.prevent="getProfileInvoices({ page: invoices.current_page + 1, search: search, date_start: date_start, date_end: date_end })" type="button"><i class="bi-chevron-right"></i></button>
                      
                      <button class="btn btn-outline-secondary" :disabled="invoices.current_page == invoices.last_page"  @click.prevent="getProfileInvoices({ page: invoices.last_page, search: search, date_start: date_start, date_end: date_end })" type="button"><i class="bi-chevron-double-right"></i></button>
                    </div>
                    <small>di {{ invoices.last_page }} pagine - {{ invoices.total ? invoices.total : 0 }} risultati</small>
                  </div>



                </div>


              </div>
              <div class="alert alert-info" v-else>
                Selezionare un codice agente per visualizzare le fatture associate
              </div>


            </div>
            <!-- INVOICES LIST | END-->



            <!-- ------------------------------------------------------------------------------------------- -->



            <!-- INVOICE DETAIL | START-->
            <div v-else>

                <header class="row mb-4">
                  <div class="col-sm-10">
                    <h3>Fattura {{ current_invoice.code }}</h3>
                    
                    <div>
                      <span v-if="current_invoice.profile">Inserito da <span class="badge badge-pill bg-dark">{{ current_invoice.profile_external_id }}</span>  <strong>{{ current_invoice.profile.company_name }}</strong> - </span>
                      <span>Emesso il <strong>{{ current_invoice.issue_date }}</strong></span>
                      
                    </div>
                  </div>
                  <div class="col-sm-2 text-end">
                    <a href="#" @click.prevent="current_invoice = null">Torna all'elenco</a>
                  </div>
                </header>
                <hr>
                
                


                <div class="row mb-4">
                  <div class="col-sm-4">
                    
                    
                    <div class="mb-2" >
                      <ProfileBadge :customer="current_invoice.customer"/>&nbsp;
                      
                      <strong>{{ current_invoice.customer.company_name }}</strong><br>
                      <span>P.IVA {{ current_invoice.customer.vat_code }}</span><br>
                      <span v-if="current_invoice.customer.price_list">Listino {{ current_invoice.customer.price_list.code }}</span><br>
                      <span v-if="current_invoice.customer.payment_mode">{{ current_invoice.customer.payment_mode.description }}</span><br>
                    </div>


                    <div>
                      <button v-if="!current_profile || !current_customer || (current_profile.id != current_invoice.profile.id) || (current_customer.id != current_invoice.customer.id)"  class="btn btn-primary btn-sm" @click.prevent="setCurrentProfile(current_invoice.profile); setCurrentCustomer(current_invoice.customer);; getCurrentInvoiceItems(); ">Attiva</button>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <h6>Note</h6> 
                    
                    <div class="mb-4" >
                      <textarea readonly class="form-control" rows="5" :value="current_invoice.notes"></textarea>
                    </div>


                    <a v-if="current_invoice.pdf_available" target="_blank" :href="getPdfUrl(current_invoice.id)" class="me-2 btn btn-secondary"><i class="bi bi-file-pdf"></i> Scarica il PDF</a>
                  </div>
                  <div class="col-sm-4">
                    <h6>Scadenze</h6>
                    <div v-for="expiration,idx in current_invoice.expirations" :key="idx">
                      <strong>{{ expiration.date }}</strong>: &euro; {{ expiration.amount }}
                    </div>
                  </div>
                </div>
 
                


                

                <div v-if="current_invoice.items && current_invoice.items.length > 0 ">
                  <h5>Prodotti</h5> 
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Codice</th>
                        <th>Descrizione</th>
                        
                        <th>Quantità</th>
                        <th>Prezzo</th>
                        <th>Totale</th>
                        
                        <th>Azioni</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in current_invoice.items" v-bind:key="item.id">
                        <td class="text-center"><span :title="item.id" class="badge badge-pill bg-dark">{{ item.product_external_id }}</span></td>
                        <td>{{ item.description }}</td>
                        
                        <td>
                            <input class="form-control form-control-sm text-end" type="text"  readonly :value="item.quantity">
                        </td>
                        <td>
                            <input class="form-control form-control-sm text-end" type="text"  readonly :value="formatPrice(item.price)">
                        </td>
                        <td>
                            <input class="form-control form-control-sm text-end" type="text"  readonly :value="formatPrice(item.total)">
                        </td>
                       
                        <td>
                            
                            <button v-if="item.product"  @click="setCurrentProduct( item.product )" class="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#cartModal">
                              <i class="bi bi-basket" v-if="item.product && item.product.current_price"></i>
                              <i class="bi bi-info-circle" v-else></i>
                            </button>
                            
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td>Totale</td>
                        <td></td>
                        <td></td>
                        
                        
                        <td>
                          <input class="form-control form-control-sm text-end" type="text"  readonly :value="formatPrice(current_invoice.total)">
                        </td>
                        <td></td>
                      </tr>
                    </tfoot>
                    
                  </table>
                 </div>
                 <div class="alert alert-info" v-else>
                   Nessun prodotto trovato in questo ordine
                 </div>

                  <hr>
                  <div class="text-end">
                    <a href="#" @click.prevent="current_invoice = null">Torna all'elenco</a>
                  </div>
            </div>
            <!-- INVOICE DETAIL | END-->

            
          </div>
        </div>

      
        
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import CmMixin from '../mixins/CmMixin'
import ProfileBadge from '../components/ProfileBadge'

export default {
  mixins: [ CmMixin ],
  components: { ProfileBadge },
  name: 'Invoices',
  data: () => {
		return {
      selected_profile: {},
      current_invoice: null,
      current_status: null,
      search: '',
      date_start: '',
      date_end: '',
			invoices: {},
      invoices_total_net: 0,
      invoices_total_vat: 0,

		}
	},
  mounted: function(){

    var self = this;

    /*
    if( this.user && this.user.role == 'admin' ){
      this.adminGetUsers();
    }
    */


    if( this.current_customer )
      this.search = this.current_customer.external_id;

    
      if( self.user.role == 'admin' ){
        self.adminGetUsers( function(){

          if( self.current_profile ){
            self.selected_profile = self.current_profile;
            self.getProfileInvoices({ search: self.search, status_id: self.current_status});  
          }
          else{
            self.getProfileInvoices();
          }
          
        });
      }
      else{
        
        if( self.current_profile ){
          self.selected_profile = self.current_profile;
          self.getProfileInvoices({ search: self.search, status_id: self.current_status});
        }
        else{
          self.getProfileInvoices();
        }

      }
    
    
  },
  methods:{

    getProfileInvoices: function( post_data ){
      var self = this;

      var external_id = this.selected_profile.external_id ? this.selected_profile.external_id : '';
    
      this.api.post( 'invoices/getProfileInvoices' + (external_id ? '/'+external_id : '') , post_data)
      .then(function (response) {
          if(response.data.invoices){
            self.notification.success( 'Fatture caricate correttamente' );
            self.invoices = response.data.invoices;
          }

          if(response.data.total_net){
            self.invoices_total_net = self.formatPrice(response.data.total_net);
          }

          if(response.data.total_vat){
            self.invoices_total_vat = self.formatPrice(response.data.total_vat);
          }

      })
    },

    getCurrentInvoiceItems: function(){
      var self = this;

      var url = 'invoices/getInvoiceItems/' + this.current_invoice.id ;
      
      if( this.current_customer)
        url = url + '&customer_external_id='+this.current_customer.external_id;
      
      this.api.get( url )
      .then(function (response) {
          if(response.data.items){
            self.notification.success( 'Elementi caricati correttamente' );
            self.current_invoice.items = response.data.items;
          }
      })
    },

    getPdfUrl: function( invoice_id ){
      return this.api_url + '/invoices/downloadPdf/' + invoice_id;
    }

  },
  computed:{
    invoices_filtered: function(){
      return this.invoices.data;
    }
  },
  watch: {
    selected_profile: function( new_val){
      this.getProfileInvoices();
    }
  }
  
  
}
</script>

<template>
  <div class="home">
    <div v-if="user.id">

    
    

    <div>
      <div class="row">
        <div class="col-sm-3">

          
          <h1 class="h3">Clienti</h1>
         
            

          <!-- CODICI AGENTE | START -->
           <div v-if="user.role == 'agent'">


            <ul class="list-group" v-if="user.profiles.length > 0">
              <li  :class="'list-group-item d-flex justify-content-between align-items-start ' + ( selected_profile && (profile.id == selected_profile.id) ? 'active' : '')" v-for="profile in user.profiles.filter( (item) => (item.customers_count > 0 )  )" v-bind:key="profile.id">

                <div>
                  <strong><a :class="( selected_profile && (profile.id == selected_profile.id) ? 'text-light' : '')" href="#" @click.prevent="selected_profile = profile; search= '';">
                    {{ profile.external_id }}
                  </a></strong><br>

                  <small class=" me-2" v-if="profile.sales_areas.length > 0">
                  Zone: <strong>{{ profile.sales_areas.map( function( item ){ return item.code; } ).join(' | ') }}</strong>
                  </small>

                  <small class=" me-2" v-if="profile.customers_count > 0">
                  Clienti associati: <strong>{{ profile.customers_count }}</strong>
                  </small>
                  
                </div>
                
                <div v-if="selected_profile">
                  <button v-if="(selected_profile.id == profile.id) && (!current_profile || current_profile.id != profile.id)"  class="btn btn-light btn-sm" @click.prevent="setCurrentProfile(profile)">Attiva</button>
                </div>

              </li>
            </ul>
          </div>
          <!-- CODICI AGENTE | END -->


          <!-- RICERCA ADMIN | START -->
           <div v-if="user.role == 'admin'">
             <!--
            <div class="input-group mb-4">
              <input type="text" class="form-control" v-model="admin_profile_search">
              <button class="btn btn-primary" :disabled="admin_profile_search.length < 3" @click.prevent="adminProfileSearch"  v-on:keyup.enter="adminProfileSearch">Cerca</button>
            </div>


            <ul class="list-group" v-if="admin_profiles.length > 0">
              <li :class="'list-group-item d-flex justify-content-between align-items-start ' + ( selected_profile && (profile.id == selected_profile.id) ? 'active' : '')" v-for="profile in admin_profiles" v-bind:key="profile.id">

                <div>
                  <strong><a :class="( selected_profile && (profile.id == selected_profile.id) ? 'text-light' : '')" href="#" @click.prevent="selected_profile = profile; search= '';">
                    {{ profile.external_id }}
                  </a></strong><br>

                  <small class="font-weight-bold">{{ ( profile.last_name + ' ' + profile.first_name ) ? ( profile.last_name + ' ' + profile.first_name ) : profile.company_name }}</small><br>

                  <small v-if="profile.sales_areas && profile.sales_areas.length > 0">
                  Zone: <strong>{{ profile.sales_areas.map( function( item ){ return item.code; } ).join(' | ') }}</strong><br>
                  </small>
                </div>
                
                <div v-if="selected_profile">
                  <button v-if="(selected_profile.id == profile.id) && (!current_profile || current_profile.id != profile.id)"  class="btn btn-light btn-sm" @click.prevent="setCurrentProfile(profile)">Attiva</button>
                </div>

              </li>
            </ul>
            -->

            <ul class="list-group" v-if="admin_users.length > 0">
              <template  v-for="user in admin_users"  :key="'u'+user.id">
                <li class="list-group-item bg-light">
                <strong>{{ user.name }}</strong>
                </li>
                <li :class="'list-group-item py-1 ' + ( selected_profile == profile ? 'bg-primary text-white' : '')" v-for="profile in user.profiles.filter( (item) => (item.customers_count > 0 ) )" :key="profile.id">
                  <strong><a href="#" :class="( selected_profile == profile ? 'text-white' : '')" @click.prevent="selected_profile = profile">{{ profile.external_id }}</a></strong><br>

                  <small class=" me-2" v-if="profile.sales_areas.length > 0">
                  Zone: <strong>{{ profile.sales_areas.map( function( item ){ return item.code; } ).join(' | ') }}</strong>
                  </small>

                  <small class=" me-2" v-if="profile.customers_count > 0">
                  Clienti associati: <strong>{{ profile.customers_count }}</strong>
                  </small>

                </li>
              </template>
              
            </ul>



              
            
          </div>
          <!-- RICERCA ADMIN | END -->



          
        </div>
        <div class="col-sm-9">
          <div v-if="customers.data">
            <div class="row mb-4">
              <div class="col-sm-8">
                <p>
                  <strong>{{ customers.total }}</strong> clienti trovati
                  <template  v-if="customers.total > 0"> - Pagina <strong>{{ customers.current_page }}</strong> di <strong>{{ customers.last_page}}</strong></template>
                </p>
              </div>
              <div class="col-sm-4">
                <div class="input-group">
                  <input v-model="search" type="text" class="form-control" placeholder="Ragione sociale, partita IVA, etc" v-on:keyup.enter="getProfileCustomers({ search: search })">
                  <button class="btn btn-primary" @click.prevent="getProfileCustomers({ search: search })">Cerca</button>
                </div>
              </div>
            </div>
            
            <div class="table-responsive">
              <table class="table table-striped table-hover">
                <tbody>
                  <tr v-for="customer in customers_filtered" v-bind:key="customer.key">
                    <td>
                      <ProfileBadge :customer="customer"/>
                    </td>
                    <td>
                      <strong>{{ customer.company_name }}</strong><br>
                      <small>{{ customer.sector }}</small>  
                      <a href="#" v-if="customer.year_data[0]" class="btn-customer-chart ms-2" @click.prevent=" selected_customer = customer;" data-bs-toggle="modal" data-bs-target="#customerModal">
                        <small><i class="bi bi-coin"></i>&nbsp;{{ customer.year_data[0] ? formatPrice(customer.year_data[0].invoices_total) + '&nbsp;&euro;' : 'N.d.' }}</small>
                      </a>
                    </td>
                    <td>
                      <small v-if="customer.addresses[0]">
                        {{ customer.addresses[0].zip_code }} <strong>{{ customer.addresses[0].city }}</strong> {{ customer.addresses[0].district ? '(' + customer.addresses[0].district + ')' : '' }}
                        <br>
                        {{ customer.addresses[0].address }}
                      </small>
                    </td>
                    <td class="text-center"><strong v-if="customer.vat_code">{{ customer.vat_code }}<br></strong>
                      <small v-if="customer.payment_mode">{{ customer.payment_mode.description }}</small>
                    </td>
                    <td>

                        <button class="btn btn-dark m-1" @click.prevent="resetCurrentVisit(); selected_customer = customer" data-bs-toggle="modal" data-bs-target="#visitModal">Visita</button>

                        <button class="btn btn-secondary m-1"  @click.prevent="if( !customer.user_note ) customer.user_note = {}; selected_customer = customer" data-bs-toggle="modal" data-bs-target="#notesModal">Info</button>

                        <!--<router-link :to="'/customers/details/' + customer.id" class="btn btn-dark">Dettagli</router-link>-->
                      
                        <button :disabled="customer.inactive" class="btn btn-primary m-1" @click.prevent="setCurrentProfile(selected_profile); setCurrentCustomer(customer)">Attiva</button>
                      
                    
                    </td>
                  </tr>
                </tbody>

              </table>
            </div>
            

            <div class="paginator row my-5"  v-if="customers.total > 0">
                <div class="col-sm-4 offset-sm-4 text-center">
                  <div class="input-group">
                    <button class="btn btn-outline-secondary" :disabled="customers.current_page == 1" @click.prevent="getProfileCustomers({ page: 1, search: search })" type="button"><i class="bi-chevron-double-left" ></i></button>
                    
                    <button class="btn btn-outline-secondary" :disabled="customers.current_page <= 1" @click.prevent="getProfileCustomers({ page: customers.current_page - 1, search: search })" type="button"><i class="bi-chevron-left" ></i></button>
                    
                    <select v-model="customers.current_page" class="form-select text-center border-secondary" @change="getProfileCustomers({ page: customers.current_page, search: search })">
                      <option v-for="p in parseInt(customers.last_page)" :key="p" :value="p">{{ p }}</option>
                    </select>
                    
                    <button class="btn btn-outline-secondary" :disabled="customers.current_page >= customers.last_page"  @click.prevent="getProfileCustomers({ page: customers.current_page + 1, search: search })" type="button"><i class="bi-chevron-right"></i></button>
                    
                    <button class="btn btn-outline-secondary" :disabled="customers.current_page == customers.last_page"  @click.prevent="getProfileCustomers({ page: customers.last_page, search: search })" type="button"><i class="bi-chevron-double-right"></i></button>
                  </div>
                  <small>di {{ customers.last_page }} pagine - {{ customers.total ? customers.total : 0 }} risultati</small>
                </div>
              </div>


          </div>
          <div class="alert alert-info" v-else>
            Selezionare un codice agente per visualizzare i clienti associati
          </div>
        </div>
      </div>

    
      
    </div>
    </div>


    <div class="modal fade" tabindex="-1" id="customerModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content" v-if="selected_customer">
              <div class="modal-header">
                  <h5 class="modal-title">{{ selected_customer.company_name }} - Fatturati</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">

                
                  <div class="mb-4" v-if="selected_customer.group">Gruppo: <strong>  {{ selected_customer.group.name}}</strong></div>

                  <strong>Fatturati</strong>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Anno</th>
                        <th class="text-end">Fatturato</th>
                        <th class="text-end">N.Fatture</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="yd in selected_customer.year_data" v-bind:key="yd.id">
                        <td><strong>{{ yd.year}}</strong></td>
                        <td class="text-end"><span class="badge badge-pill bg-dark">&euro; {{ formatPrice(yd.invoices_total) }}</span></td>
                        <td class="text-end">{{ yd.invoices_count}}</td>
                      </tr>
                    </tbody>
                  </table>
                  
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
              </div>
            </div>
        </div>
    </div>


    <div class="modal fade" tabindex="-1" id="notesModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header" v-if="selected_customer">
                  <h5 class="modal-title">{{ selected_customer.company_name }} - Scheda</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body" v-if="selected_customer && selected_customer.user_note">
                <label class="font-weight-bold mb-2">Contatti</label>
                <div v-for="contact in selected_customer.contacts" :key="contact.id" class="mb-2">

                  <span v-if="contact.type == 'phone'">
                    <i class="bi bi-telephone me-2"></i><a :href="'tel:' + contact.value">{{ contact.value }}</a>
                    <span>{{ contact.label }}</span>
                  </span>

                  <span v-if="contact.type == 'fax'">
                    <i class="bi bi-file-medical me-2"></i><a :href="'tel:' + contact.value">{{ contact.value }}</a>
                    <span>{{ contact.label }}</span>
                  </span>

                  <span v-if="contact.type == 'website'">
                    <i class="bi bi-link me-2"></i><a target="_blank" :href="'http://' + contact.value">{{ contact.value }}</a>
                    <span>{{ contact.label }}</span>
                  </span>

                  <span v-if="contact.type == 'email'">
                    <i class="bi bi-envelope me-2"></i><a :href="'mailto:' + contact.value">{{ contact.value }}</a>
                    <span>{{ contact.label }}</span>
                  </span>

                  
                </div>
                <hr>
                <div class="form-group">
                  <label class="font-weight-bold" for="">Password</label>
                  <div class="input-group">
                    <input :type="show_password ? 'text' : 'password'" :value="decodePassword(selected_customer.password)" class="form-control"/>
                    <div class="input-group-append">
                      <button class="btn btn-secondary" type="button" @click="show_password = !show_password"> <i :class="'bi ' + ( show_password ? 'bi-eye-slash' : 'bi-eye') "></i></button>
                    </div>
                  </div>
                  
                </div>
                <div class="form-group">
                  <label class="font-weight-bold" for="">Note</label>
                  <textarea rows="10" v-model="selected_customer.user_note.content" class="form-control"></textarea>
                </div>
                
              </div>
              <div class="modal-footer">
                  
                  <button type="button" class="btn btn-primary" @click="saveProfileNote" data-bs-dismiss="modal">Salva nota</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
              </div>
            </div>
        </div>
    </div>


    <div class="modal fade" tabindex="-1" id="visitModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content" v-if="selected_customer">
              <div class="modal-header" >
                  <h5 class="modal-title">{{ selected_customer.company_name }} - Aggiungi visita</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body" >

                <div class="form-group">
                  <label class="font-weight-bold" for="">Data</label>
                  <input type="date"  v-model="current_visit.date_visit" class="form-control">
                </div>
                
                <div class="form-group">
                  <label class="font-weight-bold" for="">Note</label>
                  <textarea rows="5" v-model="current_visit.notes" class="form-control"></textarea>
                </div>
                
              </div>
              <div class="modal-footer">
                  
                  <button type="button" class="btn btn-primary" @click="saveProfileVisit" data-bs-dismiss="modal" :disabled="!is_visit_valid">Salva visita</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
              </div>
            </div>
        </div>
    </div>


  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';

import CmMixin from '../mixins/CmMixin'
import ProfileBadge from '../components/ProfileBadge'

export default {
  mixins: [ CmMixin ],
  components: { ProfileBadge },
  name: 'Customers',
  data: () => {
		return {
      search: '',
			customers: {},
      selected_profile: null,
      selected_customer: null,
      show_password: false,
      current_visit: {},
		}
	},
  mounted: function(){
    if(this.current_profile){
      this.selected_profile = this.current_profile;
    }

    if( this.user.role == 'admin' ){
      this.adminGetUsers();
    }

    
      
  },
  methods:{
    getProfileCustomers: function( post_data ){

      
      var self = this;

      if(!this.selected_profile || !this.selected_profile.external_id)
        return;
      

      this.api.post( 'user/getProfileCustomers/' + this.selected_profile.external_id , post_data)
      .then(function (response) {
          if(response.data.customers){
            self.notification.success( 'Clienti caricati correttamente' );
            self.customers = response.data.customers;
          }
      })
    },

    


    adminProfileSearch: function(){

      if( this.admin_profile_search.length < 3 )
        return;

      var self = this;

      var post_data = {
        'search': this.admin_profile_search,
      };

      

       this.api.post( 'user/adminSearchProfiles', post_data)
        .then(function (response) {

          
            if(response.data.profiles){
              self.admin_profiles = response.data.profiles;
            }
        })
    },

    resetCurrentVisit: function(){

      var current_date = moment().format('YYYY-MM-DD');
      

      this.current_visit = {
        notes: '',
        date_visit: current_date,
      };
    },

    

    saveProfileNote: function(){
      if( !this.selected_customer)
        return;

        var self = this;

      var post_data = {
        'note': this.selected_customer.user_note.content,
        'user_id': this.user.id,
      };

      this.api.post( 'user/saveProfileNote/' + this.selected_customer.id, post_data)
        .then(function (response) {

          self.notification.success( 'Nota salvata correttamente' );
           
      })
    },

    saveProfileVisit: function(){
      if( !this.selected_customer)
        return;

        var self = this;

      var post_data = {
        'date_visit': this.current_visit.date_visit,
        'notes': this.current_visit.notes,
        'user_id': this.user.id,
        'profile_external_id': this.selected_profile.external_id,
        'customer_id': this.selected_customer.id,
        'customer_external_id': this.selected_customer.external_id,
      };

      this.api.post( 'visit/saveVisit/' + this.selected_customer.id, post_data)
        .then(function (response) {

          self.notification.success( 'Visita salvata correttamente' );
           
      })
    },


    decodePassword: function( encoded ){
      return atob(encoded);
    }


  },
  computed:{
    customers_filtered: function(){
      return this.customers.data;
    },

    is_visit_valid: function(){
      return this.current_visit.date_visit && ( this.current_visit.notes != '') && ( this.current_visit.notes.length > 4);
    },
  },
  watch: {
    selected_profile: function(new_val, old_val){
      this.getProfileCustomers();
    }
  }
  
}
</script>

<template>
  <div class="customer-details">
    <div v-if="customer">
      <ProfileBadge :customer="customer"/>
      <h1>{{ customer.company_name }}</h1>
      <hr>
    </div>
    <div v-else>
      Scheda cliente non disponibile
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import CmMixin from '../mixins/CmMixin'
import ProfileBadge from '../components/ProfileBadge'

export default {
  mixins: [ CmMixin ],
  components: { ProfileBadge },
  name: 'CustomerDetails',
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  data: () => {
		return {
			customer: null,
			
		}
	},
  methods:{
    getCustomer: function( customer_id ){
      var self = this;

      if(!customer_id)
        return;
      

      this.api.get( 'customer/get/' + this.id)
      .then(function (response) {

          
          if(response.data.customer){
            
            self.notification.success( 'Cliente caricato correttamente' );
            self.customer = response.data.customer;
          }
          else{
            self.notification.error( response.data.message ? response.data.message : 'Impossibile caricare il cliente selezionato' );
          }
      })
    },
  },
  mounted: function(){
    this.getCustomer( this.id );
  },
  computed:{
    
  }
  
}
</script>

<template>
  <div class="price_lists" v-if="userHasRole('admin')">
    
    <h3>Listini</h3>

    <!-- TABELLA -->
    <div>
      
      
      <table class="table" v-if="price_lists.length > 0">
        <thead>
          <tr>
            <th scope="col">Codice</th>
            <th scope="col">Nome</th>
            <th scope="col" class="text-center">Abilitato</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="pl in price_lists" v-bind:key="pl.id">
            
            <td><span class="badge rounded-pill bg-primary">{{ pl.code }}</span></td>
            <td><strong>{{ pl.description }}</strong></td>
            <td class="text-center">
              <input type="checkbox" v-model="pl.enabled" @change="updatePriceList(pl)">
            </td>
            
          </tr>
        </tbody>
      </table>
    </div>
    <!-- TABELLA / FINE -->
    
  </div>
</template>

<script>

import CmMixin from '../mixins/CmMixin'

export default {
  mixins: [ CmMixin ],
  data: () => {
		return {
      price_lists: [],

		}
	},
  mounted: function(){
    this.getPriceLists();
  },
  methods:{
     getPriceLists: function(){
       var self = this;
       this.api.get( 'price_lists')
          .then(function (response) {
            self.price_lists = response.data.price_lists;
              
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
     },
     updatePriceList: function(price_list){
       var self = this;
       this.api.post( 'price_lists/update', { price_list: price_list })
          .then(function (response) {
            self.notification.success( 'Listino aggiornato correttamente' );
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
     }
  },
}
</script>
<template>
  <div class="cart-additions" v-if="userHasRole('admin')">


    <ul class="nav nav-pills justify-content-end">
        <li class="nav-item"  v-for="group,key in additionsGrouped" :key="key">
          <router-link :to="'/cart-additions/' + key" :class="'nav-link ' + ( type == key ? 'active' : '')">{{ types[key] }}
            <span :class="'badge rounded-pill ' + ( type == key ? 'bg-white text-primary' : 'bg-primary text-white' )">{{ group.length }}</span>
          </router-link>
          
          
        </li>
        
      </ul>

    
    <div v-for="group, key in additionsGrouped" :key="key">
      <table class="table" v-if="type == key">
        <thead>
          <tr>
            <th>Attiva</th>
            <th>Codice</th>
            <th>Descrizione</th>
            <th>Priorità</th>
            <th>Solo Listino</th>
            <th>Peso DA</th>
            <th>Peso A</th>
            <th>Imponibile DA</th>
            <th>Imponibile A</th>
            
            <th v-if="type == 'shipping'">Spedizione gratuita</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ca in group" :key="ca.id" :class="ca.is_modified ? 'table-warning' : ''">
            <td><input type="checkbox" v-model="ca.enabled" @click="ca.is_modified = true"></td>
            <td><span class="badge bg-dark text-white">{{ ca.external_id }}</span></td>
            <td>{{ ca.description }}</td>
            <td><input type="number" class="form-control form-control-sm text-right" v-model="ca.priority" @change="ca.is_modified = true"></td>
            <td>
              <select class="form-select form-control-sm text-right" v-model="ca.price_list_id" @change="ca.is_modified = true">
                <option :value="null">Tutti</option>
                <option v-for="price_list in price_lists" :value="price_list.id" :key="price_list.id">{{ price_list.code }}</option>
              </select>
              
            </td>
            
            <td>
              <div class="input-group">
                <input type="number" class="form-control form-control-sm text-right" v-model="ca.weight_min" @change="ca.is_modified = true">
                <span class="input-group-text">kg</span>
              </div>
            </td>
            <td>
              <div class="input-group">
                <input type="number" class="form-control form-control-sm text-right" v-model="ca.weight_max" @change="ca.is_modified = true">
                <span class="input-group-text">kg</span>
              </div>
            </td>
            <td>
              <div class="input-group">
                <input type="number" class="form-control form-control-sm text-right" v-model="ca.amount_min" @change="ca.is_modified = true">
                <span class="input-group-text">&euro;</span>
              </div>
            </td>
            <td>
              <div class="input-group">
                <input type="number" class="form-control form-control-sm text-right" v-model="ca.amount_max" @change="ca.is_modified = true">
                <span class="input-group-text">&euro;</span>
              </div>
            </td>
            <td v-if="type == 'shipping'"><input type="checkbox" v-model="ca.is_free_shipping" @click="ca.is_modified = true"></td>
            <td>
              <button class="btn btn-sm btn-primary" @click.prevent="saveCartAddition(ca)" :disabled="!ca.is_modified">Salva</button>
            </td>
            
          </tr>
        </tbody>
      </table>  
    </div> 

  </div>
</template>

<script>

import CmMixin from '../mixins/CmMixin'

export default {
  mixins: [ CmMixin ],
  
  data: () => {
		return {
      
      
      cart_additions: [],
      types: {},
      price_lists: [],

		}
	},
  props:{
    type: {
      type: String,
      default: 'shipping',
    }
  },
  mounted: function(){

    this.getPriceLists( function(){
      
    });

    this.getCartAdditions( );
    
    
  },
  methods:{
     getCartAdditions: function( callback ){
       var self = this;
       this.api.get( 'cart_additions')
          .then(function (response) {
            self.cart_additions = response.data.cart_additions;
            self.types = response.data.types;
              
            if( typeof callback === 'function')
              callback();

          })
          .catch(function (error) {
              self.handleApiError( error );
          });
     },
     getPriceLists: function(callback){
       var self = this;
       this.api.get( 'price_lists/getActive')
          .then(function (response) {
            self.price_lists = response.data.price_lists;

            if( typeof callback === 'function')
              callback();
              
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
     },
     saveCartAddition: function(ca){

       ca.is_modified = false;

       var self = this;

       var post_data = {
         cart_addition: ca,
       };

       this.api.post( 'cart_additions/update', post_data)
          .then(function (response) {
            self.notification.success( 'Elemento salvato correttamente' );

          })
          .catch(function (error) {
              self.handleApiError( error );
          });

     }

  },

  computed: {
    additionsGrouped: function( ){
      var result = {};

      for( var i in this.cart_additions ){

        var item = this.cart_additions[i];

        if( result[ item.type ]== undefined )
          result[ item.type ] = [];

        result[ item.type ].push( item );
      }

      return result;
    }
  }
}
</script>
<template>
  <div class="carts-archive" :key="type">
    
    <div class="row mb-4">
      <div class="col-sm-6">
        <h3 v-if="type == 'quotes'">Preventivi</h3>
        <h3 v-if="type == 'temporary_orders'">Ordini temporanei</h3>
      </div>
      <div class="col-sm-6">
        <ul class="nav nav-pills justify-content-end">
          <li class="nav-item">
             <router-link to="/carts-archive/quotes" :class="'nav-link ' + ( type == 'quotes' ? 'active' : '')">Preventivi</router-link>
            <!--<a :class="'nav-link ' + ( type == 'quotes' ? 'active' : '')" href="#" @click.prevent="type = 'quotes'">Preventivi</a>-->
          </li>
          <li class="nav-item">
            <router-link to="/carts-archive/temporary_orders" :class="'nav-link ' + ( type == 'temporary_orders' ? 'active' : '')">Ordini temporanei</router-link>
            <!-- <a :class="'nav-link ' + ( type == 'temporary_orders' ? 'active' : '')" href="#" @click.prevent="type = 'temporary_orders'">Ordini temporanei</a> -->
          </li>
        </ul>
      </div>
    </div>

    

    <!-- TABELLA -->
    <div >
     
      
      <table class="table table-striped table-hover" v-if="carts.data && carts.data.length > 0">
        <thead>
          <tr>
            
            <th scope="col">Nome</th>
            <th scope="col">Creazione</th>
            <th scope="col" class="text-center">Codice Venditore</th>
            <th scope="col" class="text-center">Codice Cliente</th>
            <th scope="col" class="text-center">Totale</th>
            <th scope="col" class="text-center">Data di inserimento</th>
            <th scope="col" class="text-end">Azioni</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="c in carts.data" v-bind:key="c.id">
            
            <td><strong>{{ c.name }}</strong></td>
            <td>{{ formatDatetime(c.created_at) }}</td>
            <td class="text-center"><span class="badge badge-pill bg-dark">{{ c.profile_external_id }}</span></td>
            <td class="text-center"><span class="badge badge-pill bg-dark">{{ c.customer_external_id }}</span></td>

            <td class="text-center"><span class="badge badge-pill bg-dark">&euro;&nbsp;{{ formatPrice(c.total) }}</span></td>
            <td class="text-center">{{ formatDatetime(c.issue_date) }}</td>

            <td class="text-end">
              
              <button type="button" class="btn btn-primary mx-2" @click.prevent="setCart(c, c.issue_date)" v-text=" c.issue_date ? 'Duplica' : 'Ripristina' ">
              </button>
              <button type="button" class="btn btn-warning mx-2" :disabled="c.issue_date" @click.prevent="deleteCart(c.id)">Cancella</button>
              
            </td>
          </tr>
        </tbody>
      </table>
      <div class="alert alert-info" v-else>Nessun preventivo trovato</div>
    </div>
    <!-- TABELLA / FINE -->



    <div class="paginator row my-5"  v-if="carts.total > 0">
        <div class="col-sm-4 offset-sm-4 text-center">
          <div class="input-group">
            <button class="btn btn-outline-secondary" :disabled="carts.current_page == 1" @click.prevent="getCarts(1)" type="button"><i class="bi-chevron-double-left" ></i></button>
            <button class="btn btn-outline-secondary" :disabled="carts.current_page <= 1" @click.prevent="getCarts(carts.current_page - 1)" type="button"><i class="bi-chevron-left" ></i></button>
            <select v-model="carts.current_page" class="form-select text-center border-secondary" @change="getCarts(carts.current_page)">
              <option v-for="p in parseInt(carts.last_page)" :key="p" :value="p">{{ p }}</option>
            </select>
            <button class="btn btn-outline-secondary" :disabled="carts.current_page >= carts.last_page"  @click.prevent="getCarts(carts.current_page + 1)" type="button"><i class="bi-chevron-right"></i></button>
            <button class="btn btn-outline-secondary" :disabled="carts.current_page == carts.last_page"  @click.prevent="getCarts(carts.last_page)" type="button"><i class="bi-chevron-double-right"></i></button>
          </div>
          <small>di {{ carts.last_page }} pagine - {{ carts.total ? carts.total : 0 }} risultati</small>
        </div>
      </div>
    
  </div>
</template>

<script>

import CmMixin from '../mixins/CmMixin'

export default {
  mixins: [ CmMixin ],
  data: () => {
		return {
      carts: [],
      // current_type: null

		}
	},
  props:{
    type: {
      type: String,
    }
  },
  mounted: function(){
    // this.current_type = this.type;
    this.getCarts();
  },
  methods:{
    setCart: function( cart, reset_id ){

      if( ( this.cart.count > 0) && !this.confirm( 'Attualmente sono presenti prodotti nel carrello che ripristinando il preventivo verrebbero rimossi. Procedere comunque?' ) )
          return;

      if(reset_id && (cart.id != undefined) )
         cart.id = null;

      if( cart.profile ){
        this.$store.commit('setCurrentProfile', cart.profile);
        delete cart.profile;
      }  

      if( cart.customer ){
        this.$store.commit('setCurrentCustomer', cart.customer);
        delete cart.customer;
      }

      this.$store.commit('setCart', cart);

      this.$router.push('/cart');


    },
    getCarts: function( page ){

      if( !page )
        page = 1;

       var self = this;
       if(this.type){
         this.api.get( 'cart/archive?page=' + page + '&type=' + this.type)
          .then(function (response) {
            
            self.carts = response.data.carts;
              
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
       }

        
    },
    deleteCart: function( cart_id ){
       var self = this;
       if( self.confirm('Procedere con la cancellazione del preventivo?') ){
         this.api.delete( 'cart/delete/' + cart_id )
          .then(function (response) {
            self.getCarts();
            self.notification.success( 'Preventivo cancellato correttamente' );

              
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
       }
    }
  },

  watch: {
    
    type: function( new_val ){
      // console.log('current_type ' + new_val );
      this.getCarts();
    }
    
  },
}
</script>
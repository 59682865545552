<template>
  <div class="users" v-if="userHasRole('admin')">
    
    <h3>Utenti</h3>
    <!--<button @click.prevent="getUsers">Aggiorna</button>-->


    <!-- MODULO / FINE -->
    <div class="card" v-if="current_user">
      <div class="card-header">{{ current_user.id ? 'Modifica' : 'Crea' }} utente <strong v-if="current_user.id">{{  current_user.id }}</strong></div>
      <div class="card-body">

        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label>Nome Completo</label>
              <input type="text" class="form-control" v-model="current_user.name">
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label>Email</label>
              <input type="text" class="form-control" v-model="current_user.email">
            </div>

          </div>
          
        </div>
        
        

        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label>Codice TOP</label>
              <input type="text" class="form-control" v-model="current_user.external_id">
            </div>

          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label>Ruolo</label>
              <select class="form-control" v-model="current_user.role">
                <option value="">---</option>
                <option v-for="role, key in configuration.roles" v-bind:key="key" :value="key">{{ role }}</option>
              </select>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <div type="text" class="form-check pt-4">
                <input type="checkbox" class="form-check-input" v-model="current_user.enabled">
                <label class="form-check-label">Abilitato</label>
              </div>
            </div>
          </div>
          
        </div>


        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label>Password</label>
              <input type="password" class="form-control" v-model="current_user.password">
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label>Password - Conferma</label>
              <input type="password" class="form-control" v-model="current_user.password_confirmation">
            </div>
          </div>
        </div>
        

        

        <div class="form-actions mt-4">
          <div class="row">
            <div class="col-6">
              <button class="btn btn-primary w-100" @click="updateUser()">Salva</button>
            </div>
            <div class="col-6">
              <button class="btn btn-light w-100" @click="current_user = null ">Annulla</button>
            </div>
          </div>
        </div>


      </div>
    </div>
    <!-- MODULO / FINE -->

    <!-- TABELLA -->
    <div v-else>
      <div class="float-right">
        <button class="btn btn-primary" @click.prevent="current_user = {}">Nuovo</button>
        <button class="btn btn-light" @click.prevent="getUsers">Aggiorna</button>
      </div>
      
      <table class="table table-striped table-hover" v-if="users.length > 0">
        <thead>
          <tr>
            <th scope="col">Email</th>
            <th scope="col">Nome</th>
            <th scope="col">Codice</th>
            <th scope="col">Ruolo</th>
            <th scope="col" class="text-center">Abilitato</th>
            <th scope="col" class="text-end">Azioni</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="u in users" v-bind:key="u.id">
            
            <td><strong>{{ u.email }}</strong></td>
            <td>{{ u.name }}</td>
            <td><span class="badge rounded-pill bg-primary">{{ u.external_id }}</span></td>
            <td>{{ configuration.roles[u.role] }}</td>
            <td class="text-center"><i class="bi bi-check-square" v-if="u.enabled"></i></td>
            <td class="text-end">
              <button type="button" class="btn btn-primary mx-2" @click.prevent="current_user = u">Modifica</button>
              <button type="button" class="btn btn-warning mx-2" @click.prevent="deleteUser(u.id)">Cancella</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- TABELLA / FINE -->
    
  </div>
</template>

<script>

import CmMixin from '../mixins/CmMixin'

export default {
  mixins: [ CmMixin ],
  data: () => {
		return {
      users: [],

      current_user: null,
      validation_errors: {},
		}
	},
  mounted: function(){
    this.getUsers();
  },
  methods:{
     getUsers: function(){
       var self = this;
       this.api.get( 'users')
          .then(function (response) {
            self.users = response.data.users;
              
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
     },
     updateUser: function(){
       var self = this;
       this.api.post( 'users/update', { user: self.current_user })
          .then(function (response) {
            self.current_user = null;
            self.getUsers();
            self.notification.success( 'Utente salvato correttamente' );

              
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
     },
     deleteUser: function( user_id ){
       var self = this;
       if( self.confirm('Procedere con la cancellazione dell\'utente?') ){
         this.api.delete( 'users/delete/' + user_id )
          .then(function (response) {
            self.current_user = null;
            self.getUsers();
            self.notification.success( 'Utente cancellato correttamente' );

              
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
       }
     }
  },
}
</script>
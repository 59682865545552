<template>
  <div class="warranties">
    

    
    

      <div>
        <div class="row">
          <div class="col-sm-3">

            <h1 class="h3">Garanzie</h1>            

            

            





          </div>
          <div class="col-sm-9">
            <!-- WARRANTIES LIST | START-->
            
              <div v-if="!current_warranty">
                <div v-if="warranties.data && types">
                  <div class="row mb-4">
                    <div class="col-sm-4">
                      <p>
                        <strong>{{ warranties.total }}</strong> codici trovati
                        <template  v-if="warranties.total > 0"> - Pagina <strong>{{ warranties.current_page }}</strong> di <strong>{{ warranties.last_page}}</strong></template>
                      </p>
                    </div>
                    <div class="col-sm-8 form-inline">
                      <div class="input-group">
                        <select class="form-select" v-model="current_type" placeholder="Stato ordine">
                          <option value="">Tutti</option>
                          <option value="active">Attivate</option>
                          <option value="inactive">Non Attivate</option>
                          
                        </select>
                        <input v-model="search" type="text" class="form-control" placeholder="Ragione sociale, partita IVA, etc" v-on:keyup.enter="getWarranties({ search: search })">
                        <button class="btn btn-primary" @click.prevent="getWarranties({ search: search, type: current_type })">Cerca</button>
                      </div>
                    </div>
                  </div>
                  
                  <div class="table-responsive">
                    <table class="table table-striped table-hover" v-if="warranties.data && warranties.data.length > 0">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Codice</th>
                          <th>Tipo</th>
                          <th>Ragione sociale</th>
                          <th>Partita IVA</th>
                          
                          <th>Data Decesso</th>
                          <th>Data Registrazione</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="warranty in warranties.data" v-bind:key="warranty.id" :class=" warranty.registered_at ? 'table-success' : ''">
                          
                          <td class="text-center"><small>{{ warranty.id }}</small></td>
                          <td class="text-center"><span class="badge badge-pill bg-primary">{{ warranty.code }}</span></td>
                          <td class="text-center"><small>{{ getTypeName(warranty.type_id) }}</small></td>
                          <td><strong>{{ warranty.company_name}}</strong></td>
                          <td>{{ warranty.vat_code}}</td>
                          <td>{{ formatDate(warranty.activated_at) }}</td>
                          <td>{{ formatDate(warranty.registered_at) }}</td>
                          <td>
                            <button :disabled="!warranty.registered_at" class="btn btn-primary" @click.prevent="current_warranty = warranty">Dettagli</button>
                          </td>
                        </tr>
                      </tbody>

                    </table>
                  </div>
            

                  <div class="paginator row my-5"  v-if="warranties.total > 0">
                    <div class="col-sm-4 offset-sm-4 text-center">
                      <div class="input-group">

                        <button class="btn btn-outline-secondary" :disabled="warranties.current_page == 1" @click.prevent="getWarranties({ page: 1, search: search, type: current_type })" type="button"><i class="bi-chevron-double-left" ></i></button>
                        
                        <button class="btn btn-outline-secondary" :disabled="warranties.current_page <= 1" @click.prevent="getWarranties({ page: warranties.current_page - 1, search: search, type: current_type  })" type="button"><i class="bi-chevron-left" ></i></button>
                        
                        <select v-model="warranties.current_page" class="form-select text-center border-secondary" @change="getWarranties({ page: warranties.current_page, search: search, type: current_type  })">
                          <option v-for="p in parseInt(warranties.last_page)" :key="p" :value="p">{{ p }}</option>
                        </select>
                        
                        <button class="btn btn-outline-secondary" :disabled="warranties.current_page >= warranties.last_page"  @click.prevent="getWarranties({ page: warranties.current_page + 1, search: search, type: current_type  })" type="button"><i class="bi-chevron-right"></i></button>
                        
                        <button class="btn btn-outline-secondary" :disabled="warranties.current_page == warranties.last_page"  @click.prevent="getWarranties({ page: warranties.last_page, search: search, type: current_type  })" type="button"><i class="bi-chevron-double-right"></i></button>
                      </div>
                      <small>di {{ warranties.last_page }} pagine - {{ warranties.total ? warranties.total : 0 }} risultati</small>
                    </div>
                  </div>


                </div>
              </div>
              <div v-else>

                <h3 class="badge badge-pill bg-primary "> {{ current_warranty.code }}</h3>

                <div class="table-responsive">
                  <table class="table  table-hover" >
                    <tbody>
                      <tr>
                        <td>Tipo</td>
                        <td><strong>{{ getTypeName(current_warranty.type_id) }}</strong></td>
                      </tr>
                      <tr>
                        <td>Ragione sociale</td>
                        <td><strong>{{ current_warranty.company_name }}</strong></td>
                      </tr>
                      <!--
                      <tr>
                        <td>Rappresentante legale</td>
                        <td><strong>{{ current_warranty.legal_representative_last_name }} {{ current_warranty.legal_representative_first_name }}</strong></td>
                      </tr>
                      -->
                      <tr>
                        <td>Partita IVA</td>
                        <td><strong>{{ current_warranty.vat_code }}</strong></td>
                      </tr>
                      <!--
                      <tr>
                        <td>Codice Destinatario</td>
                        <td><strong>{{ current_warranty.invoice_code }}</strong></td>
                      </tr>

                      <tr>
                        <td>Email</td>
                        <td><strong>{{ current_warranty.email }}</strong></td>
                      </tr>

                      <tr>
                        <td>Telefono</td>
                        <td><strong>{{ current_warranty.phone }}</strong></td>
                      </tr>

                      <tr>
                        <td>Indirizzo</td>
                        <td><strong><small>{{ current_warranty.address }}<br>{{ current_warranty.zip_code }} {{ current_warranty.city }} ({{ current_warranty.district_code }})
                        
                        </small></strong></td>
                      </tr>
                      -->
                      <tr>
                        <td>Data decesso</td>
                        <td><strong>{{ formatDate(current_warranty.activated_at) }}</strong></td>
                      </tr>

                      <tr>
                        <td>Data registrazione</td>
                        <td><strong>{{ formatDate(current_warranty.registered_at) }}</strong></td>
                      </tr>

                      <tr>
                        <td>Salma</td>
                        <td><strong>{{ current_warranty.corpse_last_name }} {{ current_warranty.corpse_first_name }}</strong></td>
                      </tr>

                      <tr>
                        <td>Indirizzo IP</td>
                        <td><strong>{{ current_warranty.ip_address }}</strong></td>
                      </tr>

                      <tr>
                        <td>User Agent</td>
                        <td><small>{{ current_warranty.user_agent }}</small></td>
                      </tr>


                    </tbody>
                  </table>

                  <button class="btn btn-secondary m-2" @click.prevent="current_warranty = null">Indietro</button>
                  <button class="btn btn-primary m-2" @click.prevent="downloadWarrantyPdf( current_warranty.id, current_warranty.code, current_warranty.vat_code)">Scarica PDF</button>
                </div>

              </div>
              
            
            


            
          </div>
        </div>

      
        
      </div>
    
  </div>
</template>

<script>
// @ is an alias to /src

import CmMixin from '../mixins/CmMixin'


export default {
  mixins: [ CmMixin ],
  
  name: 'Warranties',
  data: () => {
		return {

      types: [],
      
      current_type: 'active',
      current_warranty: null,


      search: '',
			warranties: {},

      

		}
	},
  mounted: function(){

    var self = this;
    this.getTypes( function(){ 
      self.getWarranties({ type: self.current_type });
    } );
    
  },
  methods:{

    getTypes: function( callback ){

      var self = this;

      this.api.get( 'warranty/getTypes')
        .then(function (response) {

          if( response.data.types )
            self.types = response.data.types;
            
        })
        .catch(function (error) {
            self.handleApiError( error );
        })
        .finally( function(){
          if( callback )
            callback();
        });
        
    },

    getWarranties: function( post_data ){
      var self = this;

  
    
      this.api.post( 'warranty/index' , post_data)
      .then(function (response) {
          if(response.data.warranties){
            self.notification.success( 'Garanzie caricate correttamente' );
            self.warranties = response.data.warranties;
          }
      })
    },

    getTypeName: function( type_id ){
      if( !this.types )
        return '---';
      console.log( this.types );
      // var type = this.types.find( function( item ){ return item.id == type_id; })
      var type = this.types[ type_id ];
      return type ? type.name : '---';
    }

  },
  computed:{
   
  },
  watch: {
    
  }
  
  
}
</script>

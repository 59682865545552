<template>
  <div class="shops" v-if="userHasRole('admin')">
    
    <h3>Negozi</h3>
    


    <!-- MODULO / FINE -->
    <div class="card" v-if="current_shop">
      <div class="card-header">{{ current_shop.id ? 'Modifica' : 'Crea' }} negozio</div>
      <div class="card-body">

        <div class="row">
          <div class="col-sm-12 pb-4">
            <div class="form-group">
              <label>Nome Completo</label>
              <input type="text" class="form-control" v-model="current_shop.name">
            </div>
          </div>
          
          
        </div>
        
        

        <div class="row">
          <div class="col-sm-6 pb-4">
            <div class="form-group">
              <label>Descrizione</label>
              <textarea class="form-control" v-model="current_shop.description" rows="8"></textarea>
            </div>

          </div>
          <div class="col-sm-6 pb-4">
            <div class="form-group">
              <label>Dettagli anagrafici</label>
                <textarea class="form-control" v-model="current_shop.details" rows="8"></textarea>
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-3 pb-4">
            <div class="form-group">
              <label>Logo</label><br>

              <div v-if="current_shop.logo">
                <div>
                  <img :src="current_shop.logo_url" class="img-fluid my-4"> 
                </div>

                <button class="btn btn-link" @click="removeLogo">Rimuovi immagine</button>
              </div>

              <input type="file" id="logoFile" >
            </div>

            <hr  >
            <div class="form-group">
              <label>Colore istituzionale</label>

             

              <input class="ms-3" type="color" v-model="current_shop.color" >
            </div>

          </div>
          <div class="col-sm-6 pb-4">
            <div class="form-group mb-4">
              <label>Stile CSS personalizzato</label>
              <textarea class="form-control" v-model="current_shop.custom_style" rows="8"></textarea>
            </div>

            <div class="form-group">
              <label>Footer per i preventivi</label>
              <textarea class="form-control" v-model="current_shop.quote_footer" rows="8"></textarea>
            </div>
          </div>
          <div class="col-sm-3 pb-4">
            <div class="card card-body">
              <label>Utenti associati</label><br>

              <div class="form-group" v-for="manager in managers" :key="manager.id">
                <div class="form-check">
                  <input type="checkbox" :value="manager.id" :id="'manager_' + manager.id" v-model="current_shop.users_ids" class="form-check-input">
                  <label :for="'manager_' + manager.id" class="form-check-label">{{ manager.name }}</label>
                </div>
              </div>
            </div>

          </div>
        </div>


        <div class="form-actions mt-4">
          <div class="row">
            <div class="col-6">
              <button class="btn btn-primary w-100" @click="updateShop()">Salva</button>
            </div>
            <div class="col-6">
              <button class="btn btn-light w-100" @click="current_shop = null ">Annulla</button>
            </div>
          </div>
        </div>


      </div>
    </div>
    <!-- MODULO / FINE -->

    <!-- TABELLA -->
    <div v-else>
      <div class="float-right">
        <button class="btn btn-primary" @click.prevent="current_shop = { users_ids: []}">Nuovo</button>
        <button class="btn btn-light" @click.prevent="getShops">Aggiorna</button>
      </div>
      
      <table class="table table-striped table-hover" v-if="shops.length > 0">
        <thead>
          <tr>
            
            <th scope="col">Nome</th>
            
            <th scope="col" class="text-end">Azioni</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="s in shops" v-bind:key="s.id">
            
            
            <td>{{ s.name }}</td>
            <td><span class="badge rounded-pill bg-primary">{{ s.external_id }}</span></td>
            <td class="text-end">
              <button type="button" class="btn btn-primary mx-2" @click.prevent="current_shop = s">Modifica</button>
              <button type="button" class="btn btn-warning mx-2" @click.prevent="deleteShop(s.id)">Cancella</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- TABELLA / FINE -->
    
  </div>
</template>

<script>

import CmMixin from '../mixins/CmMixin'

export default {
  mixins: [ CmMixin ],
  data: () => {
		return {
      shops: [],
      managers: [],

      current_shop: null,
      validation_errors: {},
		}
	},
  mounted: function(){
    this.getShops();
    this.getManagers();
  },
  methods:{
     getShops: function(){
       var self = this;
       this.api.get( 'shops')
          .then(function (response) {
            self.shops = response.data.shops;
              
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
     },
     getManagers: function(){
       var self = this;
       this.api.get( 'shops/getManagers')
          .then(function (response) {
            self.managers = response.data.managers;
              
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
     },
     updateShop: function(){

      var post_data = new FormData();

      post_data.append('id', ( this.current_shop.id ? this.current_shop.id : '' ) );
      post_data.append('name', ( this.current_shop.name ? this.current_shop.name : '' ));
      post_data.append('description', ( this.current_shop.description ? this.current_shop.description : '' ));
      post_data.append('details', ( this.current_shop.details ? this.current_shop.details : '' ));
      post_data.append('custom_style', ( this.current_shop.custom_style ? this.current_shop.custom_style : '' ));
      post_data.append('users_ids', ( this.current_shop.users_ids ? this.current_shop.users_ids : [] ));
      post_data.append('quote_footer', ( this.current_shop.quote_footer ? this.current_shop.quote_footer : '' ) );
      post_data.append('color', ( this.current_shop.color ? this.current_shop.color : '' ) );

      var logoFile =  document.getElementById("logoFile");
      
      
      if(  logoFile.files ){
        post_data.append('logo', logoFile.files[0] );
      }


       var self = this;
       this.api.post( 'shops/update', post_data, { "Content-Type": "multipart/form-data" })
          .then(function (response) {
            self.current_shop = null;
            self.getShops();
            self.notification.success( 'Negozio salvato correttamente' );

              
          })
          .catch(function (error) {
              self.handleApiError( error );
          });



          
     },
     deleteShop: function( shop_id ){
       var self = this;
       if( self.confirm('Procedere con la cancellazione dell\'utente?') ){
         this.api.delete( 'shops/delete/' + shop_id )
          .then(function (response) {
            self.current_shop = null;
            self.getShops();
            self.notification.success( 'Utente cancellato correttamente' );

              
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
       }
     },

     removeLogo: function(){
       var self = this;
       if( self.confirm('Procedere con la rimozione dell\'immagine?') ){
         this.api.get( 'shops/removeLogo/' + this.current_shop.id )
          .then(function (response) {
            self.current_shop.logo = null;
            self.notification.success( 'Logo rimosso correttamente' );
              
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
       }
     },
  },
}
</script>
<template>
  <div class="catalog">
    <div v-if="user.id">

      <div>
        <div class="row">
          <!--
          <div class="col-sm-3">

            <h1 class="h3 mb-4">Catalogo</h1>
            <ul class="list-group">
              <li class="list-group-item" v-for="price_list in price_lists" v-bind:key="price_list.id">
                <span class="form-check">
                  <input type="checkbox"  class="form-check-input" v-model="price_list.checked">
                  <span class="form-check-label"><strong>{{ price_list.code }}</strong> <small>{{ price_list.description}}</small></span>
                </span>    
              </li>
            </ul>
          
            <a class="btn btn-primary w-100 mt-4"  href="#" @click.prevent="getProducts()">Carica prodotti</a>

          </div>
          -->
          <div class="col-sm-12">
            <div class="row mb-4">
                <div class="col-sm-4">
                  <h1>Catalogo</h1>
                </div>
                <div class="col-sm-4">
                  <p class="mt-3">
                    <strong>{{ products.total ? products.total : 0 }}</strong> prodotti trovati 
                    <template  v-if="products.total > 0"> - Pagina <strong>{{ products.current_page }}</strong> di <strong>{{ products.last_page}}</strong></template>
                  </p>
                </div>
                <div class="col-sm-4">
                  <div class="input-group">
                    <input v-model="search" type="text" class="form-control" placeholder="Descrizione" v-on:keyup.enter="getProducts({ search: search })">
                    <button class="btn btn-primary" @click.prevent="getProducts({ search: search })">Cerca</button>
                  </div>
                </div>
              </div>


              <div class="row justify-content-center" v-if="products">
                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 pb-3" v-for="product in products_filtered" v-bind:key="product.key">
                  <div class="card card-product h-100">
                    <CatalogImage :code="product.external_id" :key="product.external_id" :imgclass="'card-img-top'"/>
                    <div class="card-body p-2">
                      <span :title="product.id" class="badge badge-pill bg-dark">{{ product.external_id }}</span>
                      <br>
                      <!--
                      <small class="ms-1">{{ product.categories.filter( function( item ){ return item.enabled; } ).map( function( item ){ return item.description; } ).join(' - ') }}</small>
                      -->
                      
                      <div class="h6 my-3">{{ product.description }}</div>

                      
                    </div>
                    <div class="card-footer">
                       
                            <div class="row mb-2">
                              <div class="col-8 small">Disponibilità: <strong>{{ product.quantity }}/{{ product.quantity_code }}</strong></div>
                              <div class="col-4 text-end"><span class=" badge badge-pill badge-price bg-dark" v-if="product.current_price">
                            {{ formatPrice(product.current_price) }} &euro;
                              </span>
                            </div>
                            </div>
                            


                            <div class="text-end">
                              <div class="btn-group" role="group" aria-label="Azioni prodotto">
                                <a class="btn btn-secondary" target="_blank" :href="'https://docs.coccatoemezzetti.com/pagine/PDF_cat.php?art=' + product.external_id">
                                  <i class="bi bi-file-pdf"></i>
                                </a>
                                <button  @click="setCurrentProduct( product )" class="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#cartModal">
                                  <i class="bi bi-basket" v-if="product.current_price"></i>
                                  <i class="bi bi-info-circle" v-else></i>
                                </button>
                              
                              </div>
                            </div>
                            
                         
                            

                    </div>
                  </div>
                </div>
              </div>

           


              <div class="paginator row my-5"  v-if="products.total > 0">
                <div class="col-sm-4 offset-sm-4 text-center">
                  <div class="input-group">
                    <button class="btn btn-outline-secondary" :disabled="products.current_page == 1" @click.prevent="getProducts({ page: 1, search: search })" type="button"><i class="bi-chevron-double-left" ></i></button>
                    <button class="btn btn-outline-secondary" :disabled="products.current_page <= 1" @click.prevent="getProducts({ page: products.current_page - 1, search: search })" type="button"><i class="bi-chevron-left" ></i></button>
                    <select v-model="products.current_page" class="form-select text-center border-secondary" @change="getProducts({ page: products.current_page, search: search })">
                      <option v-for="p in parseInt(products.last_page)" :key="p" :value="p">{{ p }}</option>
                    </select>
                    <button class="btn btn-outline-secondary" :disabled="products.current_page >= products.last_page"  @click.prevent="getProducts({ page: products.current_page + 1, search: search })" type="button"><i class="bi-chevron-right"></i></button>
                    <button class="btn btn-outline-secondary" :disabled="products.current_page == products.last_page"  @click.prevent="getProducts({ page: products.last_page, search: search })" type="button"><i class="bi-chevron-double-right"></i></button>
                  </div>
                  <small>di {{ products.last_page }} pagine - {{ products.total ? products.total : 0 }} risultati</small>
                </div>
              </div>
              
                

          </div>
        </div>

      
        
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import CmMixin from '../mixins/CmMixin'
import CatalogImage from '../components/CatalogImage'


export default {
  mixins: [ CmMixin ],
  components: { CatalogImage },
  name: 'Catalog',
  data: () => {
		return {
      search: '',
			products: {},
      price_lists: [], // non li sto usando
			
		}
	},
  mounted: function(){
    // this.getActivePriceLists();
    
    this.getProducts();
  },
  methods:{
    /*
    getActivePriceLists: async function( ){
      console.log( 'getActivePriceLists');
      try{
        var response = await this.api.get( 'price_lists/getActive');
        if(response.data.price_lists){
          this.notification.success( 'Listini caricati correttamente' );
          this.price_lists = response.data.price_lists.map( (item) => { item.checked = true; return item; });
        }
      }
      catch( e ){
        console.log('Error in getActivePriceLists',);
      }

      
      return true;
    },
    getProducts: async function( post_data ){
      console.log( 'getProducts');
      var self = this;

      if(post_data == undefined ||  post_data.price_list_ids == undefined ){

        if(!post_data)
          var post_data = {};
        post_data.price_list_ids = this.price_lists.filter( (item) => { return item.checked; }).map( (item) => { return item.id});
      } 

      var response = await this.api.post( 'catalog/getAllProducts' , post_data);

      console.log( 'Reponse', response );
      
      if(response.data.products){
        self.notification.success( 'Prodotti caricati correttamente' );
        self.products = response.data.products;
      }
      
      return true;
    },
    */
    
    getActivePriceLists: function( ){ // al momento non la sto usando
      var self = this;

      this.api.get( 'price_lists/getActive')
      .then(function (response) {
          if(response.data.price_lists){
            self.notification.success( 'Listini caricati correttamente' );
            self.price_lists = response.data.price_lists.map( (item) => { item.checked = true; return item; });
            self.getProducts();
          }
          
      })
    },
    
    getProducts: function( post_data ){
      var self = this;

      

      // carico solo i prodotti dei listini selezionati
      if(post_data == undefined ||  post_data.price_list_ids == undefined ){
        if(!post_data)
          var post_data = {};
        post_data.price_list_ids = this.price_lists.filter( (item) => { return item.checked; }).map( (item) => { return item.id});
      } 

      // passo l'id dell'cliente corrente per sapere il prezzo corrente
      if(this.current_customer){
        post_data.current_price_list_id = (this.current_customer.price_list ? this.current_customer.price_list.id : null);
        post_data.customer_external_id = this.current_customer.external_id;
      }

      this.api.post( 'catalog/getProducts' , post_data)
      .then(function (response) {
          if(response.data.products){
            self.notification.success( 'Prodotti caricati correttamente' );
            self.products = response.data.products;
          }
      })
    },
    
    
  },
  computed:{
    products_filtered: function(){
        return this.products.data;
    }
  }
  
}
</script>

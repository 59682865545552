<template>
  <div class="payment-modes" v-if="userHasRole('admin')">
    
    <h3>Modalità di pagamento</h3>

    <!-- TABELLA -->
    <div>
      
      
      <table class="table" v-if="payment_modes.length > 0">
        <thead>
          <tr>
            <th scope="col">Codice</th>
            <th scope="col">Nome</th>
            <th scope="col" class="text-center">Sconto</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="pc in payment_modes" v-bind:key="pc.id" :class="pc.is_modified ? 'table-warning' : ''">
            
            <td><span class="badge rounded-pill bg-primary">{{ pc.external_id }}</span></td>
            <td><strong>{{ pc.description }}</strong></td>
            <td><input type="number" class="form-control text-end" step=".10" v-model="pc.discount" @change="pc.is_modified = true"></td>
            <td class="text-center">
              <button class="btn btn-primary" @click.prevent="updatePaymentMode(pc)">Salva</button>
            </td>
            
          </tr>
        </tbody>
      </table>
    </div>
    <!-- TABELLA / FINE -->
    
  </div>
</template>

<script>

import CmMixin from '../mixins/CmMixin'

export default {
  mixins: [ CmMixin ],
  data: () => {
		return {
      payment_modes: [],

		}
	},
  mounted: function(){
    this.getPaymentModes();
  },
  methods:{
     getPaymentModes: function(){
       var self = this;
       this.api.get( 'payment_modes')
          .then(function (response) {
            self.payment_modes = response.data.payment_modes;
              
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
     },
     updatePaymentMode: function(payment_mode){
       var self = this;
       this.api.post( 'payment_modes/update', { payment_mode: payment_mode })
          .then(function (response) {
            self.notification.success( 'Modalità di pagamento aggiornata correttamente' );
            payment_mode.is_modified = false;
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
     }
  },
}
</script>
<template>
  <div class="jobs" v-if="userHasRole('admin')">
    

    <div class="row mb-4">
      <div class="col-sm-6">
        <h3>Jobs</h3>
      </div>
      <div class="col-sm-6 ">
        <ul class="nav nav-pills justify-content-end">
          <li class="nav-item">
            <a :class="'nav-link ' + ( current_state == 'commands' ? 'active' : '')" href="#" @click.prevent="current_state = 'commands'">Comandi</a>
          </li>
          <li class="nav-item">
            <a :class="'nav-link ' + ( current_state == 'queue' ? 'active' : '')" href="#" @click.prevent="current_state = 'queue'">Coda</a>
          </li>
        </ul>
      </div>
    </div>

    <hr>
    


    <div v-if="current_state == 'commands'">

      <h5>Elenco comandi</h5>

      <table class="table table-bordered" >
        <thead>
          <tr>
            <th>Priorità</th>
            <th>Comando</th>
            <th>Argomenti</th>
            <th>Opzioni</th>
            <th>Ultima esecuzione</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody> 
          <tr v-for="c,i in commands" :key="i">
            <td class="text-center">
              <span class="badge bg-dark">{{ c.priority }}</span>
            </td>
            <td>
              <strong class="font-monospace">{{ c.name}}</strong><br>
              <small>{{ c.description }}</small>
            </td>
            <td>
              <div class="form-group" v-for="a,j in c.arguments" :key="j">
                <input type="text" class="form-control form-control-sm" :placeholder="a.name" v-model="a.value">
              </div>
            </td>

            <td>
              <div class="form-group" v-for="o,k in c.options" :key="k">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" v-model="o.checked">
                  <label class="form-check-label">{{ o.name}}</label>
                </div>
              </div>
            </td>
            <td>
              <small v-if="c.history.length > 0">
                Avviato il <strong>{{ formatDatetime(c.history[0].finished_at) }}</strong><br>
                Durata <strong>{{ c.history[0].elapsed_time }}</strong>
              </small>
              
            </td>
            <td class="text-center">
              <button class="btn btn-primary btn-sm" @click.prevent="executeCommand(c)">Accoda</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="current_state == 'queue'">


      <div class="text-end">
        <a href="#" @click.prevent="getQueue">Aggiorna</a>
      </div>
      <div class="table-responsive">
        <table class="table table-striped" v-if="commands_history.data">
          <thead>
            <tr>
              <th>Comando</th>
              <th>Parametri</th>
              <th>Tempo impiegato</th>
              <th>Creazione</th>
              <th>Avvio</th>
              <th>Fine</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="ch,idx in commands_history.data" :key="idx" :class="ch.completed ? 'table-success' : ( ch.started_at ? 'table-warning' : '') ">
              <td><strong class="font-monospace">{{ ch.name }}</strong></td>
              <td><small class="font-monospace">{{ ch.params }}</small></td>
              <td>{{ ch.elapsed_time }}</td>
              <td class="text-end small">{{ formatDatetime(ch.created_at) }}</td>
              <td class="text-end small">{{ formatDatetime(ch.started_at) }}</td>
              <td class="text-end small">{{ formatDatetime(ch.finished_at) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="paginator row my-5"  v-if="commands_history.total > 0">
        <div class="col-sm-4 offset-sm-4 text-center">
          <div class="input-group">
            <button class="btn btn-outline-secondary" :disabled="commands_history.current_page == 1" @click.prevent="getQueue(1)" type="button"><i class="bi-chevron-double-left" ></i></button>
            <button class="btn btn-outline-secondary" :disabled="commands_history.current_page <= 1" @click.prevent="getQueue(commands_history.current_page - 1)" type="button"><i class="bi-chevron-left" ></i></button>
            <select v-model="commands_history.current_page" class="form-select text-center border-secondary" @change="getQueue(commands_history.current_page)">
              <option v-for="p in parseInt(commands_history.last_page)" :key="p" :value="p">{{ p }}</option>
            </select>
            <button class="btn btn-outline-secondary" :disabled="commands_history.current_page >= commands_history.last_page"  @click.prevent="getQueue(commands_history.current_page + 1)" type="button"><i class="bi-chevron-right"></i></button>
            <button class="btn btn-outline-secondary" :disabled="commands_history.current_page == commands_history.last_page"  @click.prevent="getQueue(commands_history.last_page)" type="button"><i class="bi-chevron-double-right"></i></button>
          </div>
          <small>di {{ commands_history.last_page }} pagine - {{ commands_history.total ? commands_history.total : 0 }} risultati</small>
        </div>
      </div>

      
    </div>
    
  </div>
</template>

<script>

import CmMixin from '../mixins/CmMixin'

export default {
  mixins: [ CmMixin ],
  data: () => {
		return {
      
      current_state: 'commands',

      commands: [],
      
      commands_history: {},
      




		}
	},
  mounted: function(){

    var self = this;
    this.getCommands( function(){
      
    });
    
  },
  methods:{
     getCommands: function( callback ){
       var self = this;
       this.api.get( 'job/getCommands')
          .then(function (response) {
            self.commands = response.data.commands.sort( function(a,b){ return a.priority - b.priority; });
              
            if( typeof callback === 'function')
              callback();

          })
          .catch(function (error) {
              self.handleApiError( error );
          });
     },

     getQueue: function(page, callback){

       if(!page)
        page = 1;


       var self = this;
       this.api.get( 'job/getQueue?page=' + page)
          .then(function (response) {
            
            self.commands_history = response.data.commands_history;

            if( typeof callback === 'function')
              callback();
              
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
     },

     executeCommand: function(c){
       var self = this;
       this.api.post( 'job/executeCommand', { command: c})
          .then(function (response) {
            console.log( response.data );
            if( response.data.success ){
              self.notification.success('Commando accodato correttamente');
            }
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
     },
  },

  watch: {
    current_state: function( new_val){
      if( new_val == 'queue')
        this.getQueue();
    }
  }
}
</script>
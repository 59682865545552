<template>
  <div class="orders">
    <div v-if="user && user.id">

    
    

      <div>
        <div class="row">
          <div class="col-sm-3">

            <h1 class="h3">Ordini</h1>            

            <!-- SIDEBAR AGENT | START -->
            <div v-if="user.role == 'agent'">

              <ul class="list-group" v-if="user.profiles.length > 0">

                <li :class="'list-group-item py-1 ' + ( selected_profile.id == undefined ? 'bg-primary' : '')"  >
                  <strong><a href="#" :class="( selected_profile.id == undefined ? 'text-white' : '')" @click.prevent="current_order = null; selected_profile = {}">Tutti i codici agente</a></strong>
                </li>

                <li :class="'list-group-item ' + (profile.id == selected_profile.id ? 'active' : '')" v-for="profile in user.profiles" v-bind:key="profile.id">
                  <strong><a href="#" :class=" (profile.id == selected_profile.id ? 'text-light' : '')" @click.prevent="current_order = null; selected_profile = profile; search= ''; current_order = null;">{{ profile.external_id }}</a></strong><br>
                  <small v-if="profile.sales_areas.length > 0">Zone: <strong>{{ profile.sales_areas.map( function( item ){ return item.code; } ).join(' - ') }}</strong></small>
                </li>
              </ul>
            </div>
            <!-- SIDEBAR AGENT | END -->


            <!-- SIDEBAR ADMIN | START -->
            <div v-if="user.role == 'admin'">
              <ul class="list-group" v-if="admin_users.length > 0">
                <li class="list-group-item bg-light">
                  <strong><small>Generale</small></strong>
                </li>
                <li :class="'list-group-item py-1 ' + ( selected_profile.id == undefined ? 'bg-primary' : '')"  >
                  <strong><a href="#" :class="( selected_profile.id == undefined ? 'text-white' : '')" @click.prevent="current_order = null; selected_profile = {}">Tutti gli agenti</a></strong>
                </li>
                <template  v-for="user in admin_users"  :key="'u'+user.id">
                  <li class="list-group-item bg-light">
                  <strong><small>{{ user.name }}</small></strong>
                  </li>
                  <template v-if=" user.profiles.length > 0">
                    <li v-for="profile in user.profiles" :class="'list-group-item py-1 ' + ( selected_profile.id == profile.id ? 'bg-primary' : '')"  :key="profile.id">
                      <strong><a href="#" :class="( selected_profile.id == profile.id ? 'text-white' : '')" @click.prevent="current_order = null; selected_profile = profile">{{ profile.external_id }}</a></strong>
                    </li>
                  </template>
                </template>
                
              </ul>
              
            </div>
            <!-- SIDEBAR ADMIN | END -->



          </div>
          <div class="col-sm-9">
            <!-- ORDERS LIST | START-->
            <div v-if="!current_order">

              <div v-if="orders.data">
                <div class="row mb-4">
                  <div class="col-sm-2">
                    <p class="small">
                      <strong>{{ orders.total }}</strong> ordini trovati
                      <template  v-if="orders.total > 0"><br>Pagina <strong>{{ orders.current_page }}</strong> di <strong>{{ orders.last_page}}</strong></template>
                    </p>
                  </div>
                  <div class="col-sm-10 d-flex">

                    <!-- FILTERS | START -->
                    <div class="form-group flex-fill pe-2">
                      <label class="small">Stato</label>
                      <select class="form-select form-control-sm" v-model="current_status">
                        <option :value="null">Tutti gli stati</option>
                        <option v-for="st in status_list" :key="st.id" :value="st.id">{{ st.name }} ({{ st.code }})</option>
                      </select>
                    </div>
                    <div class="form-group flex-fill pe-2">
                      <label class="small">A partire dal</label>
                      <input type="date" class="form-control form-control-sm" v-model="date_start" placeholder="Da">
                    </div>
                    <div class="form-group flex-fill pe-2">
                      <label class="small">Fino al</label>
                      <input type="date" class="form-control form-control-sm" v-model="date_end" placeholder="A">
                    </div>
                    <div class="form-group flex-fill pe-2">
                      <label class="small">Ricerca libera</label>
                      <input v-model="search" type="text" class="form-control form-control-sm" placeholder="Ragione sociale, partita IVA, etc" v-on:keyup.enter="getProfileOrders({ search: search })">
                     
                    </div>
                    <button class="btn btn-primary" @click.prevent="getProfileOrders({ search: search, status_id: current_status, date_start: date_start, date_end: date_end })">Cerca</button>
                    
                    
                    <!-- FILTERS | END -->
                    <hr>
                  </div>
                </div>
                
                <div class="table-responsive">
                  <table class="table table-striped table-hover" v-if="orders.data && orders.data.length > 0">
                    <thead>
                      <tr>
                        <th>Numero Ordine</th>
                        <th>Codice Cliente</th>
                        <th>Cliente</th>
                        
                        <th>Codice Agente</th>
                        <th>Data Emissione</th>
                        <th>Totale netto</th>
                        <th>Emesso</th>
                        <th>Evaso</th>
                        <th>Stato</th>
                        <!-- <th>Fatturato</th> -->
                        <th>Azioni</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="order in orders_filtered" v-bind:key="order.key">
                        <td class="text-center"><span class="badge badge-pill bg-secondary">{{ order.code }}</span></td>
                        <td class="text-center">
                          <ProfileBadge :customer="order.customer"/>
                        </td>
                        <td>{{ order.customer.company_name }}</td>
                        <td class="text-center"><span class="badge badge-pill bg-dark">{{ order.profile_external_id }}</span></td>
                        <td><small>{{ order.issue_date }}</small></td>
                        <td class="text-end">
                          <span class=" badge badge-pill badge-price bg-dark" v-if="order.total_net">
                            {{ formatPrice(order.total_net) }} &euro;
                          </span>
                        </td>
                        <td class="text-center">
                          <i class="bi bi-check-square" v-if="order.issued"></i>
                        </td>
                        <td class="text-center">
                          <i class="bi bi-check-square" v-if="order.processed"></i>
                        </td>
                        <!--
                        <td class="text-center">
                          <i class="bi bi-check-square" v-if="order.invoiced"></i>
                        </td>
                        -->
                        <td class="text-center">
                          <small v-if="order.status" class="font-weight-bold">
                          {{ order.status.name }}
                          </small>
                        </td>
                        <td>
                          <button @click="current_order = order; getCurrentOrderItems(); " class="btn btn-primary"><i class="bi bi-card-checklist"></i>
                            </button>
                        </td>
                      </tr>
                    </tbody>

                  </table>
                </div>
          

                <div class=" row my-5"  v-if="orders.total > 0">

                  <div class="col-6 col-sm-6">
                    
                    <div class="form-group">
                      <label for="">Totale netto</label>
                      <input type="text " class="form-control text-end" readonly v-model="orders_total_net">
                    </div>
                  </div>

                  <!--
                  <div class="col-6 col-sm-3">
                    <div class="form-group">
                      <label for="">Totale ivato</label>
                      <input type="text text-right" class="form-control" readonly v-model="orders_total_vat">
                    </div>
                  </div>
                  -->
                    
                    
                  
                  <div class="paginator col-sm-6 text-right">
                    <br>
                    <div class="input-group">

                      <button class="btn btn-outline-secondary" :disabled="orders.current_page == 1" @click.prevent="getProfileOrders({ page: 1, search: search, date_start: date_start, date_end: date_end, status_id: current_status })" type="button"><i class="bi-chevron-double-left" ></i></button>
                      
                      <button class="btn btn-outline-secondary" :disabled="orders.current_page <= 1" @click.prevent="getProfileOrders({ page: orders.current_page - 1, search: search, date_start: date_start, date_end: date_end, status_id: current_status })" type="button"><i class="bi-chevron-left" ></i></button>
                      
                      <select v-model="orders.current_page" class="form-select text-center border-secondary" @change="getProfileOrders({ page: orders.current_page, search: search, date_start: date_start, date_end: date_end, status_id: current_status })">
                        <option v-for="p in parseInt(orders.last_page)" :key="p" :value="p">{{ p }}</option>
                      </select>
                      
                      <button class="btn btn-outline-secondary" :disabled="orders.current_page >= orders.last_page"  @click.prevent="getProfileOrders({ page: orders.current_page + 1, search: search, date_start: date_start, date_end: date_end, status_id: current_status })" type="button"><i class="bi-chevron-right"></i></button>
                      
                      <button class="btn btn-outline-secondary" :disabled="orders.current_page == orders.last_page"  @click.prevent="getProfileOrders({ page: orders.last_page, search: search, date_start: date_start, date_end: date_end, status_id: current_status })" type="button"><i class="bi-chevron-double-right"></i></button>
                    </div>
                    <small>di {{ orders.last_page }} pagine - {{ orders.total ? orders.total : 0 }} risultati</small>
                  </div>



                </div>


              </div>
              <div class="alert alert-info" v-else>
                Selezionare un codice agente per visualizzare gli ordini associati
              </div>
            </div>
            <!-- ORDERS LIST | END-->



            <!-- ------------------------------------------------------------------------------------------- -->



            <!-- ORDER DETAIL | START-->
            <div v-else>

                <header class="row mb-4">
                  <div class="col-sm-10">
                    <h3>Ordine {{ current_order.code }}</h3>
                    
                    <div>
                      <span v-if="current_order.profile">Inserito da <span class="badge badge-pill bg-dark">{{ current_order.profile_external_id }}</span>  <strong>{{ current_order.profile.company_name }}</strong> - </span>
                      <span>Emesso il <strong>{{ current_order.issue_date }}</strong></span>
                      
                    </div>
                  </div>
                  <div class="col-sm-2 text-end">
                    <a href="#" @click.prevent="current_order = null">Torna all'elenco</a>
                  </div>
                </header>
                <hr>
                
                <div class="row mb-4">
                  <div class="col-6 text-center">
                      <div class="list-group">
                        <div class="list-group-item">
                          Emesso
                        </div>
                        <div :class="'list-group-item  text-light ' + (current_order.issued ? 'bg-success' : 'bg-danger') ">
                          <span class="h4">{{ current_order.issued ? 'SI' : 'NO' }}</span>
                        </div>
                      </div>
                  </div>
                  <div class="col-6 text-center">
                    <div class="list-group">
                        <div class="list-group-item">
                          Evaso
                        </div>
                        <div :class="'list-group-item  text-light ' + (current_order.processed ? 'bg-success' : 'bg-danger') ">
                          <div class="h4">{{ current_order.processed ? 'SI' : 'NO' }}</div>

                        </div>
                      </div>
                  </div>
                  <!-- 
                  <div class="col-4 text-center">
                    <div class="list-group">
                        <div class="list-group-item">
                          Fatturato
                        </div>
                        <div :class="'list-group-item  text-light ' + (current_order.invoiced ? 'bg-success' : 'bg-danger') ">
                          <span class="h4">{{ current_order.invoiced ? 'SI' : 'NO' }}</span>
                        </div>
                      </div>
                  </div>
                  -->
                </div>


                <div class="row mb-4">
                  <div class="col-sm-4">
                    
                    <div class="mb-2" >
                      <ProfileBadge :customer="current_order.customer"/>&nbsp;
                      
                      <strong>{{ current_order.customer.company_name }}</strong><br>
                      <span>P.IVA {{ current_order.customer.vat_code }}</span><br>
                      <span v-if="current_order.customer.price_list">Listino {{ current_order.customer.price_list.code }}</span><br>
                      <span v-if="current_order.customer.payment_mode">{{ current_order.customer.payment_mode.description }}</span><br>
                    </div>


                    <div>
                      <button v-if="!current_profile || !current_customer || (current_profile.id != current_order.profile.id) || (current_customer.id != current_order.customer.id)"  class="btn btn-primary btn-sm" @click.prevent="setCurrentProfile(current_order.profile); setCurrentCustomer(current_order.customer);; getCurrentOrderItems(); ">Attiva</button>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <h6>Note all'ordine</h6> 
                    
                    <div class="mb-4" >
                      <textarea readonly class="form-control" rows="5" :value="current_order.notes"></textarea>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <h6>Destinazione</h6> 
                    
                    <div class="mb-4">
                      <address>
                        <span v-if="current_order.shipping_name"><strong >{{  current_order.shipping_name }}</strong></span><br>
                        <small v-if="current_order.shipping_address">{{  current_order.shipping_address }}</small><br>
                        <small>{{  current_order.shipping_zip_code }} {{  current_order.shipping_city }}</small>
                      </address>
                    </div>
                  </div>
                </div>
 
                


                

                <div v-if="current_order.items && current_order.items.length > 0 ">
                  <h5>Prodotti</h5> 
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Codice</th>
                        <th>Descrizione</th>
                        <th>Prezzo</th>
                        <th>Quantità</th>
                        <th>Sconto 1</th>
                        <th>Sconto 2</th>
                        
                        
                        <th>Totale</th>
                        <th>Azioni</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in current_order.items" v-bind:key="item.id">
                        <td class="text-center"><span :title="item.id" class="badge badge-pill bg-dark">{{ item.product_external_id }}</span></td>
                        <td>{{ item.description }}</td>
                        <td>
                            <input class="form-control form-control-sm text-end" type="text"  readonly :value="formatPrice(item.price)">
                        </td>
                        <td>
                            <input class="form-control form-control-sm text-end" type="text"  readonly :value="item.quantity">
                        </td>
                        <td>
                            <input class="form-control form-control-sm text-end" type="text"  readonly :value="item.discount_1">
                        </td>
                        <td>
                            <input class="form-control form-control-sm text-end" type="text"  readonly :value="item.discount_2">
                            
                        </td>
                        
                        <td>
                            <input class="form-control form-control-sm text-end" type="text"  readonly :value="formatPrice(item.total)">
                        </td>
                        <td>
                            <button v-if="item.product"  @click="setCurrentProduct( item.product )" class="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#cartModal">
                              <i class="bi bi-basket" v-if="item.product && item.product.current_price"></i>
                              <i class="bi bi-info-circle" v-else></i>
                            </button>
                            <small v-else>Prodotto non trovato</small>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td>Totale</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <input class="form-control form-control-sm text-end" type="text"  readonly :value="formatPrice(current_order.total_net)">
                        </td>
                        <td>
                          <input class="form-control form-control-sm text-end" type="text"  readonly :value="formatPrice(current_order.total)">
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                 </div>
                 <div class="alert alert-info" v-else>
                   Nessun prodotto trovato in questo ordine
                 </div>

                  <hr>
                  <div class="text-end">
                    <a href="#" @click.prevent="current_order = null">Torna all'elenco</a>
                  </div>
            </div>
            <!-- ORDER DETAIL | END-->

            
          </div>
        </div>

      
        
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import CmMixin from '../mixins/CmMixin'
import ProfileBadge from '../components/ProfileBadge'

export default {
  mixins: [ CmMixin ],
  components: { ProfileBadge },
  name: 'Orders',
  data: () => {
		return {
      selected_profile: {},
      current_order: null,
      current_status: null,
      search: '',
      date_start: '',
      date_end: '',
			orders: {},

			orders_total_net: 0,
			orders_total_vat: 0,

      status_list: [],

		}
	},
  mounted: function(){

    var self = this;

    /*
    if( this.user && this.user.role == 'admin' ){
      this.adminGetUsers();
    }
    */


    if( this.current_customer )
      this.search = this.current_customer.external_id;

    this.getOrderStatus( function(){
      if( self.user.role == 'admin' ){
        self.adminGetUsers( function(){

          if( self.current_profile ){
            self.selected_profile = self.current_profile;
            self.getProfileOrders({ search: self.search, status_id: self.current_status});  
          }
          else{
            self.getProfileOrders();
          }
          
        });
      }
      else{
        
        if( self.current_profile ){
          self.selected_profile = self.current_profile;
          self.getProfileOrders({ search: self.search, status_id: self.current_status});
        }
        else{
          self.getProfileOrders();
        }

      }
      
    });

    
    
  },
  methods:{

    getOrderStatus: function( callback ){
      var self = this;
    
      this.api.get( 'orders/getStatusList')
      .then(function (response) {
          if(response.data.status){
            self.notification.success( 'Stati caricati correttamente' );
            self.status_list = response.data.status;
          }

          if( typeof callback === 'function')
            callback();
      })
    },

    getProfileOrders: function( post_data ){
      var self = this;

      var external_id = this.selected_profile.external_id ? this.selected_profile.external_id : '';

      

    
      this.api.post( 'orders/getProfileOrders' + (external_id ? '/'+external_id : '') , post_data)
      .then(function (response) {
          if(response.data.orders){
            self.notification.success( 'Ordini caricati correttamente' );
            self.orders = response.data.orders;
          }

          if(response.data.total_net){
            self.orders_total_net = self.formatPrice(response.data.total_net);
          }

          if(response.data.total_vat){
            self.orders_total_vat = self.formatPrice(response.data.total_vat);
          }


      })
    },

    getCurrentOrderItems: function(){
      var self = this;

      var url = 'orders/getOrderItems/' + this.current_order.id + '?price_list_id=' + this.current_order.customer.price_list_id;
      
      if( this.current_customer)
        url = url + '&customer_external_id='+this.current_customer.external_id;
      
      this.api.get( url )
      .then(function (response) {
          if(response.data.items){
            self.notification.success( 'Elementi caricati correttamente' );
            self.current_order.items = response.data.items;
          }
      })
    },
  },
  computed:{
    orders_filtered: function(){
      return this.orders.data;
    }
  },
  watch: {
    selected_profile: function( new_val){
      this.getProfileOrders();
    }
  }
  
  
}
</script>

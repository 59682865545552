<template>
  <div class="warranty-job" :key="section">
    
    <div class="row mb-4">
      <div class="col-sm-6">
        <h3 v-if="section == 'create'">Genera codici</h3>
        <h3 v-if="section == 'list'">Elenco generazioni</h3>
      </div>
      <div class="col-sm-6">
        <ul class="nav nav-pills justify-content-end">
          <li class="nav-item">
             <router-link to="/warranty-job/create" :class="'nav-link ' + ( section == 'create' ? 'active' : '')">Genera</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/warranty-job/list" :class="'nav-link ' + ( section == 'list' ? 'active' : '')">Elenco</router-link>
          </li>
        </ul>
      </div>
    </div>

    

    <!-- CREATION | START -->
    <div v-if="section == 'create'">


      <div class="card card-body">

        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="year">Anno</label>
              <input type="number" class="form-control" v-model="year" @blur="checkJob(year, batch)">
            </div>

            <div class="form-group">
              <label for="batch">Batch</label>
              <input type="number" class="form-control" v-model="batch" @blur="checkJob(year, batch)">
            </div>

            <div class="form-group">
              <label for="type_id">Tipo</label>
              <select class="form-control" v-model="type_id">
                <option value="">---</option>
                <option v-for="type in types" :value="type.id" :key="type.id" v-html="type.name"></option>
              </select>
            </div>

            <div class="form-group">
              <label for="year">Codici da generare</label>
              <input type="number" class="form-control" v-model="codes_count" :max="batch_max">
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="year">Note</label>
              <textarea class="form-control" rows="8" v-model="notes"></textarea>
            </div>
          </div>
        </div>

        <div class="form-actions mt-4">
          <button class="btn btn-primary w-100" :disabled="!check_valid" @click.prevent="startJob">Genera codici</button>
        </div>

      </div>
    </div>
    <!-- CREATION | END -->

    <!-- LIST | START -->
    <div v-if="section == 'list'">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Anno</th>
            <th>Batch</th>
            <th>Tipo</th>
            <th>Codici Richiesti</th>
            <th>Codici Generati</th>
            <th>Note</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="job in jobs" :key="job.id">
            <td>{{ job.year }}</td>
            <td>{{ job.batch }}</td>
            <td>{{ getTypeName(job.type_id) }}</td>
            <td>{{ job.codes_count }}</td>
            <td>{{ job.warranties_count }}</td>
            <td><small>{{ job.notes }}</small></td>
            <td class="text-end">
              
              <button v-if="job?.type?.supports_activation" class="btn btn-secondary m-2" @click.prevent="downloadActivationCsv(job.id)">CSV Codici attivazione
              </button>
              <button v-if="job?.type?.supports_activation" class="btn btn-primary m-2" @click.prevent="downloadActivationPdf(job.id)">PDF Schede attivazione</button>
              <button v-if="job?.type?.supports_validation" class="btn btn-primary m-2" @click.prevent="downloadValidationPdf(job.id)">PDF Etichette validazione</button>
              
             
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- LIST | END -->
    
     
      
    
  </div>
</template>

<script>

import CmMixin from '../mixins/CmMixin'

export default {
  mixins: [ CmMixin ],
  data: () => {
		return {


      types: [],

      year: null,
      batch: null,
      type_id: null,
      codes_count: 10,
      notes: null,

      batch_max: 0,
      check_valid: false,
      jobs: [],
      // current_type: null

		}
	},
  props:{
    section: {
      type: String,
    }
  },
  mounted: function(){
    // this.current_type = this.type;

    var self = this;

    this.getTypes();
    
    this.getJobs( function(){
      self.checkJob();
    });
    
  },
  methods:{

    checkJob: function( year, batch ){

       var self = this;
       
       
        this.api.get( 'warranty/checkJob?year=' + ( year ? year : '' ) + '&batch=' + ( batch ? batch : '')  )
        .then(function (response) {
          
          if(response.data.success){
            self.check_valid = true;
            self.year = response.data.year;
            self.batch = response.data.batch;
          }
            
        })
        .catch(function (error) {

          console.log('ERROR');
            self.handleApiError( error );
        });
       

        
    },

    getTypes: function( callback ){


      var self = this;
      
      this.api.get( 'warranty/getTypes')
        .then(function (response) {

          if( response.data.types )
            self.types = response.data.types;

            

          if(response.data.types.length > 0){
            self.type_id = response.data.types[0].id;
          }

          
            
        })
        .catch(function (error) {
            self.handleApiError( error );
        });
      

        
    },
    
    getJobs: function( callback ){


      var self = this;
       
      this.api.get( 'warranty/getJobs')
        .then(function (response) {

          if( response.data.jobs )
            self.jobs = response.data.jobs;

          if( response.data.batch_max ){
            self.batch_max = response.data.batch_max;
            self.codes_count = response.data.batch_max;
          }
            

          if( typeof callback === 'function')
              callback( response );
            
        })
        .catch(function (error) {
            self.handleApiError( error );
        });
       

        
    },


    startJob: function( callback ){
      if( confirm('Procedere con la creazione dei codici?') ){


        var self = this;
        var data = {
            year: self.year,
            batch: self.batch,
            type_id: self.type_id,
            codes_count: self.codes_count,
            notes: self.notes,
        };

        this.api.post( 'warranty/startJob', data)
          .then(function (response) {
            

            if( response.data.success )
              self.notification.success(  response.data.message ?  response.data.message : 'Operazione conclusa correttamente' );

            self.getJobs( function(){
              self.checkJob( self.year );
            });
            

            if( typeof callback === 'function')
                callback( response );
              
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
      }
    },

    downloadActivationCsv: function( job_id ){

      var self = this;
      
      this.api.get( 'warranty/createJobCsv/'+job_id, { responseType: 'blob' } )
        .then(function (response) {
          
          
          if( response.data.success != undefined && response.data.success == false){
            self.notification.error( response.data.message );
          }
          else{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'cm-warranties-' + job_id + '.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
            
        })
        .catch(function (error) {
            self.handleApiError( error );
        });

          
      
    },


    downloadActivationPdf: function( job_id ){

      var self = this;
      
      this.api.get( 'warranty/createJobPdf/'+job_id, { responseType: 'blob' } )
        .then(function (response) {
          
          
          if( response.data.success != undefined && response.data.success == false){
            self.notification.error( response.data.message );
          }
          else{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'cm-warranties_job-' + job_id + '.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
            
        })
        .catch(function (error) {
            self.handleApiError( error );
        });

          
      
    },


    downloadValidationPdf: function( job_id ){


      var self = this;

      this.api.get( 'warranty/createJobValidationPdf/'+job_id, { responseType: 'blob' } ) // TODO: da creare
        .then(function (response) {
          
          
          if( response.data.success != undefined && response.data.success == false){
            self.notification.error( response.data.message );
          }
          else{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'cm-warranties_job_validation-' + job_id + '.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
            
        })
        .catch(function (error) {
            self.handleApiError( error );
        });

          

      },

    getTypeName: function( type_id ){
      if( !this.types ) return '---';
      var type = this.types[type_id];
      return type ? type.name : '---';
    }
    
  },

 
}
</script>
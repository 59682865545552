<template>
  <div class="shipping-documents">
    <div v-if="user && user.id">

    
    

      <div>
        <div class="row">
          <div class="col-sm-3">

            <h1 class="h3">Documenti di trasporto</h1>            

            <!-- SIDEBAR AGENT | START -->
            <div v-if="user.role == 'agent'">

              <ul class="list-group" v-if="user.profiles.length > 0">

                <li :class="'list-group-item py-1 ' + ( selected_profile.id == undefined ? 'bg-primary' : '')"  >
                  <strong><a href="#" :class="( selected_profile.id == undefined ? 'text-white' : '')" @click.prevent="current_shipping_document = null; selected_profile = {}">Tutti i codici agente</a></strong>
                </li>

                <li :class="'list-group-item ' + (profile.id == selected_profile.id ? 'active' : '')" v-for="profile in user.profiles" v-bind:key="profile.id">
                  <strong><a href="#" :class=" (profile.id == selected_profile.id ? 'text-light' : '')" @click.prevent="current_shipping_document = null; selected_profile = profile; search= ''; current_shipping_document = null;">{{ profile.external_id }}</a></strong><br>
                  <small v-if="profile.sales_areas.length > 0">Zone: <strong>{{ profile.sales_areas.map( function( item ){ return item.code; } ).join(' - ') }}</strong></small>
                </li>
              </ul>
            </div>
            <!-- SIDEBAR AGENT | END -->


            <!-- SIDEBAR ADMIN | START -->
            <div v-if="user.role == 'admin'">
              <ul class="list-group" v-if="admin_users.length > 0">
                <li class="list-group-item bg-light">
                  <strong><small>Generale</small></strong>
                </li>
                <li :class="'list-group-item py-1 ' + ( selected_profile.id == undefined ? 'bg-primary' : '')"  >
                  <strong><a href="#" :class="( selected_profile.id == undefined ? 'text-white' : '')" @click.prevent="current_shipping_document = null; selected_profile = {}">Tutti gli agenti</a></strong>
                </li>
                <template  v-for="user in admin_users"  :key="'u'+user.id">
                  <li class="list-group-item bg-light">
                  <strong><small>{{ user.name }}</small></strong>
                  </li>
                  <template v-if=" user.profiles.length > 0">
                    <li v-for="profile in user.profiles" :class="'list-group-item py-1 ' + ( selected_profile.id == profile.id ? 'bg-primary' : '')"  :key="profile.id">
                      <strong><a href="#" :class="( selected_profile.id == profile.id ? 'text-white' : '')" @click.prevent="current_shipping_document = null; selected_profile = profile">{{ profile.external_id }}</a></strong>
                    </li>
                  </template>
                </template>
                
              </ul>
              
            </div>
            <!-- SIDEBAR ADMIN | END -->



          </div>
          <div class="col-sm-9">
            <!-- DOCUMENTS LIST | START-->
            <div v-if="!current_shipping_document">

              <div v-if="shipping_documents.data">
                <div class="row mb-4">
                  <div class="col-sm-2">
                    <p class="small">
                      <strong>{{ shipping_documents.total }}</strong> documenti trovati
                      <template  v-if="shipping_documents.total > 0"><br>Pagina <strong>{{ shipping_documents.current_page }}</strong> di <strong>{{ shipping_documents.last_page}}</strong></template>
                    </p>
                  </div>
                  <div class="col-sm-10 d-flex">

                    <!-- FILTERS | START -->
                    
                    <div class="form-group flex-fill pe-2">
                      <label class="small">A partire dal</label>
                      <input type="date" class="form-control form-control-sm" v-model="date_start" placeholder="Da">
                    </div>
                    <div class="form-group flex-fill pe-2">
                      <label class="small">Fino al</label>
                      <input type="date" class="form-control form-control-sm" v-model="date_end" placeholder="A">
                    </div>
                    <div class="form-group flex-fill pe-2">
                      <label class="small">Ricerca libera</label>
                      <input v-model="search" type="text" class="form-control form-control-sm" placeholder="Ragione sociale, partita IVA, etc" v-on:keyup.enter="getProfileShippingDocuments({ search: search })">
                     
                    </div>
                    <button class="btn btn-primary" @click.prevent="getProfileShippingDocuments({ search: search, status_id: current_status, date_start: date_start, date_end: date_end })">Cerca</button>
                    
                    
                    <!-- FILTERS | END -->
                    <hr>
                  </div>
                </div>
                
                <div class="table-responsive">
                  <table class="table table-striped table-hover" v-if="shipping_documents.data && shipping_documents.data.length > 0">
                    <thead>
                      <tr>
                        <th>Numero Documento</th>
                        <th>Codice Cliente</th>
                        <th>Cliente</th>
                        
                        <th>Codice Agente</th>
                        <th>Data Emissione</th>
                        <th>Causale</th>
                        <th>Numero Colli</th>
                        <th>Porto</th>
                        <!-- <th>Fatturato</th> -->
                        <th>Azioni</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="document in shipping_documents_filtered" v-bind:key="document.key">
                        <td class="text-center"><span class="badge badge-pill bg-secondary">{{ document.code }}</span></td>
                        <td class="text-center">
                          <ProfileBadge :customer="document.customer"/>
                        </td>
                        <td>{{ document.customer.company_name }}</td>
                        <td class="text-center"><span class="badge badge-pill bg-dark">{{ document.profile_external_id }}</span></td>
                        <td><small>{{ document.issue_date }}</small></td>
                        <td><small class="font-weight-bold">{{ document.causal }}</small></td>
                        <td class="text-center"><span class="badge bg-primary">{{ document.packages_count }}</span></td>
                        
                        <td><small>{{ document.freight }}</small></td>
                        
                        <!--
                        <td class="text-center">
                          <i class="bi bi-check-square" v-if="document.invoiced"></i>
                        </td>
                        -->
                       
                        <td>
                          <div class="d-flex justify-content-end">
                            <a v-if="document.pdf_available" target="_blank" :href="getPdfUrl(document.id)" class="me-2 btn btn-secondary"><i class="bi bi-file-pdf"></i></a>



                            <button @click="current_shipping_document = document; getCurrentShippingDocumentItems(); " class="btn btn-primary"><i class="bi bi-card-checklist"></i>
                            </button>
                          </div>
                          
                        </td>
                      </tr>
                    </tbody>

                  </table>
                </div>
          

                <div class=" row my-5"  v-if="shipping_documents.total > 0">

                  <div class="col-6 col-sm-6">
                    
                   
                  </div>
                  
                  <div class="paginator col-sm-6 text-right">
                    <br>
                    <div class="input-group">

                      <button class="btn btn-outline-secondary" :disabled="shipping_documents.current_page == 1" @click.prevent="getProfileShippingDocuments({ page: 1, search: search, date_start: date_start, date_end: date_end })" type="button"><i class="bi-chevron-double-left" ></i></button>
                      
                      <button class="btn btn-outline-secondary" :disabled="shipping_documents.current_page <= 1" @click.prevent="getProfileShippingDocuments({ page: shipping_documents.current_page - 1, search: search, date_start: date_start, date_end: date_end })" type="button"><i class="bi-chevron-left" ></i></button>
                      
                      <select v-model="shipping_documents.current_page" class="form-select text-center border-secondary" @change="getProfileShippingDocuments({ page: shipping_documents.current_page, search: search, date_start: date_start, date_end: date_end })">
                        <option v-for="p in parseInt(shipping_documents.last_page)" :key="p" :value="p">{{ p }}</option>
                      </select>
                      
                      <button class="btn btn-outline-secondary" :disabled="shipping_documents.current_page >= shipping_documents.last_page"  @click.prevent="getProfileShippingDocuments({ page: shipping_documents.current_page + 1, search: search, date_start: date_start, date_end: date_end })" type="button"><i class="bi-chevron-right"></i></button>
                      
                      <button class="btn btn-outline-secondary" :disabled="shipping_documents.current_page == shipping_documents.last_page"  @click.prevent="getProfileShippingDocuments({ page: shipping_documents.last_page, search: search, date_start: date_start, date_end: date_end })" type="button"><i class="bi-chevron-double-right"></i></button>
                    </div>
                    <small>di {{ shipping_documents.last_page }} pagine - {{ shipping_documents.total ? shipping_documents.total : 0 }} risultati</small>
                  </div>



                </div>


              </div>
              <div class="alert alert-info" v-else>
                Selezionare un codice agente per visualizzare i DDT associati
              </div>
            </div>
            <!-- DOCUMENTS LIST | END-->



            <!-- ------------------------------------------------------------------------------------------- -->



            <!-- DOCUMENT DETAIL | START-->
            <div v-else>

                <header class="row mb-4">
                  <div class="col-sm-10">
                    <h3>DDT {{ current_shipping_document.code }}</h3>
                    
                    <div>
                      <span v-if="current_shipping_document.profile">Inserito da <span class="badge badge-pill bg-dark">{{ current_shipping_document.profile_external_id }}</span>  <strong>{{ current_shipping_document.profile.company_name }}</strong> - </span>
                      <span>Emesso il <strong>{{ current_shipping_document.issue_date }}</strong></span>
                      
                    </div>
                  </div>
                  <div class="col-sm-2 text-end">
                    <a href="#" @click.prevent="current_shipping_document = null">Torna all'elenco</a>
                  </div>
                </header>
                <hr>
                
                


                <div class="row mb-4">
                  <div class="col-sm-4">
                    
                    
                    <div class="mb-2" >
                      <ProfileBadge :customer="current_shipping_document.customer"/>&nbsp;
                      
                      <strong>{{ current_shipping_document.customer.company_name }}</strong><br>
                      <span>P.IVA {{ current_shipping_document.customer.vat_code }}</span><br>
                      <span v-if="current_shipping_document.customer.price_list">Listino {{ current_shipping_document.customer.price_list.code }}</span><br>
                      <span v-if="current_shipping_document.customer.payment_mode">{{ current_shipping_document.customer.payment_mode.description }}</span><br>
                    </div>


                    <div>
                      <button v-if="!current_profile || !current_customer || (current_profile.id != current_shipping_document.profile.id) || (current_customer.id != current_shipping_document.customer.id)"  class="btn btn-primary btn-sm" @click.prevent="setCurrentProfile(current_shipping_document.profile); setCurrentCustomer(current_shipping_document.customer);; getCurrentShippingDocumentItems(); ">Attiva</button>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <h6>Note</h6> 
                    
                    <div class="mb-4" >
                      <textarea readonly class="form-control" rows="5" :value="current_shipping_document.notes"></textarea>
                    </div>


                    <a v-if="current_shipping_document.pdf_available" target="_blank" :href="getPdfUrl(current_shipping_document.id)" class="me-2 btn btn-secondary"><i class="bi bi-file-pdf"></i> Scarica il PDF</a>
                  </div>
                  <div class="col-sm-4">
                    <dl>
                      <dt>Causale</dt>
                      <dd v-html="current_shipping_document.causal"></dd>

                      <dt>Numero Colli</dt>
                      <dd v-html="current_shipping_document.packages_count"></dd>

                      <dt>Aspetto</dt>
                      <dd v-html="current_shipping_document.appearance"></dd>

                      <dt>Porto</dt>
                      <dd v-html="current_shipping_document.freight"></dd>
                    </dl>
                  </div>
                </div>
 
                


                

                <div v-if="current_shipping_document.items && current_shipping_document.items.length > 0 ">
                  <h5>Prodotti</h5> 
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Codice</th>
                        <th>Descrizione</th>
                        
                        <th>Quantità</th>
                        <th>Unità di misura</th>
                        
                        <th>Azioni</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in current_shipping_document.items" v-bind:key="item.id">
                        <td class="text-center"><span :title="item.id" class="badge badge-pill bg-dark">{{ item.product_external_id }}</span></td>
                        <td>{{ item.description }}</td>
                        
                        <td>
                            <input class="form-control form-control-sm text-end" type="text"  readonly :value="item.quantity">
                        </td>
                        <td>
                            <input class="form-control form-control-sm text-end" type="text"  readonly :value="item.unit_of_measure">
                        </td>
                       
                        <td>
                            
                            <button v-if="item.product"  @click="setCurrentProduct( item.product )" class="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#cartModal">
                              <i class="bi bi-basket" v-if="item.product && item.product.current_price"></i>
                              <i class="bi bi-info-circle" v-else></i>
                            </button>
                        </td>
                      </tr>
                    </tbody>
                    
                  </table>
                 </div>
                 <div class="alert alert-info" v-else>
                   Nessun prodotto trovato in questo ordine
                 </div>

                  <hr>
                  <div class="text-end">
                    <a href="#" @click.prevent="current_shipping_document = null">Torna all'elenco</a>
                  </div>
            </div>
            <!-- DOCUMENT DETAIL | END-->

            
          </div>
        </div>

      
        
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import CmMixin from '../mixins/CmMixin'
import ProfileBadge from '../components/ProfileBadge'

export default {
  mixins: [ CmMixin ],
  components: { ProfileBadge },
  name: 'ShippingDocuments',
  data: () => {
		return {
      selected_profile: {},
      current_shipping_document: null,
      current_status: null,
      search: '',
      date_start: '',
      date_end: '',
			shipping_documents: {},


		}
	},
  mounted: function(){

    var self = this;

    /*
    if( this.user && this.user.role == 'admin' ){
      this.adminGetUsers();
    }
    */


    if( this.current_customer )
      this.search = this.current_customer.external_id;

    
      if( self.user.role == 'admin' ){
        self.adminGetUsers( function(){

          if( self.current_profile ){
            self.selected_profile = self.current_profile;
            self.getProfileShippingDocuments({ search: self.search, status_id: self.current_status});  
          }
          else{
            self.getProfileShippingDocuments();
          }
          
        });
      }
      else{
        
        if( self.current_profile ){
          self.selected_profile = self.current_profile;
          self.getProfileShippingDocuments({ search: self.search, status_id: self.current_status});
        }
        else{
          self.getProfileShippingDocuments();
        }

      }
      
    

    
    
  },
  methods:{

    getProfileShippingDocuments: function( post_data ){
      var self = this;

      var external_id = this.selected_profile.external_id ? this.selected_profile.external_id : '';
    
      this.api.post( 'shipping_documents/getProfileShippingDocuments' + (external_id ? '/'+external_id : '') , post_data)
      .then(function (response) {
          if(response.data.shipping_documents){
            self.notification.success( 'DDT caricati correttamente' );
            self.shipping_documents = response.data.shipping_documents;
          }



      })
    },

    getCurrentShippingDocumentItems: function(){
      var self = this;

      var url = 'shipping_documents/getShippingDocumentItems/' + this.current_shipping_document.id ;
      
      if( this.current_customer)
        url = url + '&customer_external_id='+this.current_customer.external_id;
      
      this.api.get( url )
      .then(function (response) {
          if(response.data.items){
            self.notification.success( 'Elementi caricati correttamente' );
            self.current_shipping_document.items = response.data.items;
          }
      })
    },

    getPdfUrl: function( document_id ){
      return this.api_url + '/shipping_documents/downloadPdf/' + document_id;
    }

  },
  computed:{
    shipping_documents_filtered: function(){
      return this.shipping_documents.data;
    }
  },
  watch: {
    selected_profile: function( new_val){
      this.getProfileShippingDocuments();
    }
  }
  
  
}
</script>

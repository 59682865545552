<template>
  <div class="product-group" v-if="userHasRole('admin')">
    
    <h3>Gruppi di prodotti</h3>

    <!-- TABELLA -->
    <div>
      
      
      <table class="table" v-if="product_groups.length > 0">
        <thead>
          <tr>
            <th scope="col">Codice</th>
            <th scope="col">Nome</th>
            <th scope="col" class="text-center">Da importare</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="pg in product_groups" v-bind:key="pg.id">
            
            <td><span class="badge rounded-pill bg-primary">{{ pg.external_id }}</span></td>
            <td><strong>{{ pg.description }}</strong></td>
            <td class="text-center">
              <input type="checkbox" v-model="pg.to_import" @change="updateProductGroup(pg)">
            </td>
            
          </tr>
        </tbody>
      </table>
    </div>
    <!-- TABELLA / FINE -->
    
  </div>
</template>

<script>

import CmMixin from '../mixins/CmMixin'

export default {
  mixins: [ CmMixin ],
  data: () => {
		return {
      product_groups: [],

		}
	},
  mounted: function(){
    this.getProductGroups();
  },
  methods:{
     getProductGroups: function(){
       var self = this;
       this.api.get( 'product_groups')
          .then(function (response) {
            self.product_groups = response.data.product_groups;
              
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
     },
     updateProductGroup: function(product_group){
       var self = this;
       this.api.post( 'product_groups/update', { product_group: product_group })
          .then(function (response) {
            self.notification.success( 'Gruppo aggiornato correttamente' );
          })
          .catch(function (error) {
              self.handleApiError( error );
          });
     }
  },
}
</script>
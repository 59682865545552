<template>
  <div class="ordered-products">
    <div v-if="user && user.id">

    
    

      <div>
        <div class="row">
          <div class="col-sm-3">

            <h1 class="h3">Visite</h1>            

            <!-- SIDEBAR AGENT | START -->
            <div v-if="user.role == 'agent'">

              <ul class="list-group" v-if="user.profiles.length > 0">

                <li :class="'list-group-item py-1 ' + ( selected_profile.id == undefined ? 'bg-primary' : '')"  >
                  <strong><a href="#" :class="( selected_profile.id == undefined ? 'text-white' : '')" @click.prevent=" selected_profile = {}">Tutti i codici agente</a></strong>
                </li>

                <li :class="'list-group-item ' + (profile.id == selected_profile.id ? 'active' : '')" v-for="profile in user.profiles" v-bind:key="profile.id">
                  <strong><a href="#" :class=" (profile.id == selected_profile.id ? 'text-light' : '')" @click.prevent="selected_profile = profile; search= ''; ">{{ profile.external_id }}</a></strong><br>
                  <small v-if="profile.sales_areas.length > 0">Zone: <strong>{{ profile.sales_areas.map( function( item ){ return item.code; } ).join(' - ') }}</strong></small>
                </li>
              </ul>
            </div>
            <!-- SIDEBAR AGENT | END -->


            <!-- SIDEBAR ADMIN | START -->
            <div v-if="user.role == 'admin'">
              <ul class="list-group" v-if="admin_users.length > 0">
                <li class="list-group-item bg-light">
                  <strong><small>Generale</small></strong>
                </li>
                <li :class="'list-group-item py-1 ' + ( selected_profile.id == undefined ? 'bg-primary' : '')"  >
                  <strong><a href="#" :class="( selected_profile.id == undefined ? 'text-white' : '')" @click.prevent="selected_profile = {}">Tutti gli agenti</a></strong>
                </li>
                <template  v-for="user in admin_users"  :key="'u'+user.id">
                  <li class="list-group-item bg-light">
                  <strong><small>{{ user.name }}</small></strong>
                  </li>
                  <template v-if=" user.profiles.length > 0">
                    <li v-for="profile in user.profiles" :class="'list-group-item py-1 ' + ( selected_profile.id == profile.id ? 'bg-primary' : '')"  :key="profile.id">
                      <strong><a href="#" :class="( selected_profile.id == profile.id ? 'text-white' : '')" @click.prevent="selected_profile = profile">{{ profile.external_id }}</a></strong>
                    </li>
                  </template>
                </template>
                
              </ul>
              
            </div>
            <!-- SIDEBAR ADMIN | END -->



          </div>
          <div class="col-sm-9">
            <!-- DOCUMENTS LIST | START-->
            <div v-if="!current_shipping_document">

              <div v-if="visits.data">
                <div class="row mb-4">
                  <div class="col-sm-2">
                    <p class="small">
                      <strong>{{ visits.total }}</strong> prodotti trovati
                      <template  v-if="visits.total > 0"><br>Pagina <strong>{{ visits.current_page }}</strong> di <strong>{{ visits.last_page}}</strong></template>
                    </p>
                  </div>
                  <div class="col-sm-10 d-flex">

                    <!-- FILTERS | START -->
                    
                    <div class="form-group flex-fill pe-2">
                      <label class="small">A partire dal</label>
                      <input type="date" class="form-control form-control-sm" v-model="date_start" placeholder="Da">
                    </div>
                    <div class="form-group flex-fill pe-2">
                      <label class="small">Fino al</label>
                      <input type="date" class="form-control form-control-sm" v-model="date_end" placeholder="A">
                    </div>
                    <div class="form-group flex-fill pe-2">
                      <label class="small">Ricerca libera</label>
                      <input v-model="search" type="text" class="form-control form-control-sm" placeholder="Ragione sociale, partita IVA, etc" v-on:keyup.enter="getProfileVisits({ search: search })">
                     
                    </div>
                    <button class="btn btn-primary" @click.prevent="getProfileVisits({ search: search, date_start: date_start, date_end: date_end })">Cerca</button>
                    
                    
                    <!-- FILTERS | END -->
                    <hr>
                  </div>
                </div>
                
                <div class="table-responsive">
                  <table class="table table-striped table-hover" v-if="visits.data && visits.data.length > 0">
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Codice Agente</th>
                        <th>Codice Cliente</th>
                        <th>Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in visits_filtered" :key="item.id">
                        
                        <td><small>{{ item.date_visit }}</small></td>
                        <td class="text-center"><span class="badge badge-pill bg-dark">{{ item.profile_external_id }}</span></td>
                        <td class="text-center">
                          <ProfileBadge :customer="item.customer"/>
                        </td>
                        
                        <td><small>{{ item.notes }}</small></td>
                        
                       
                      </tr>
                    </tbody>

                  </table>
                </div>
          

                <div class=" row my-5"  v-if="visits.total > 0">

                  <div class="col-6 col-sm-6">
                    
                   
                  </div>

                    
                    
                  
                  <div class="paginator col-sm-6 text-right">
                    <br>
                    <div class="input-group">

                      <button class="btn btn-outline-secondary" :disabled="visits.current_page == 1" @click.prevent="getProfileVisits({ page: 1, search: search, date_start: date_start, date_end: date_end })" type="button"><i class="bi-chevron-double-left" ></i></button>
                      
                      <button class="btn btn-outline-secondary" :disabled="visits.current_page <= 1" @click.prevent="getProfileVisits({ page: visits.current_page - 1, search: search, date_start: date_start, date_end: date_end })" type="button"><i class="bi-chevron-left" ></i></button>
                      
                      <select v-model="visits.current_page" class="form-select text-center border-secondary" @change="getProfileVisits({ page: visits.current_page, search: search, date_start: date_start, date_end: date_end })">
                        <option v-for="p in parseInt(visits.last_page)" :key="p" :value="p">{{ p }}</option>
                      </select>
                      
                      <button class="btn btn-outline-secondary" :disabled="visits.current_page >= visits.last_page"  @click.prevent="getProfileVisits({ page: visits.current_page + 1, search: search, date_start: date_start, date_end: date_end })" type="button"><i class="bi-chevron-right"></i></button>
                      
                      <button class="btn btn-outline-secondary" :disabled="visits.current_page == visits.last_page"  @click.prevent="getProfileVisits({ page: visits.last_page, search: search, date_start: date_start, date_end: date_end })" type="button"><i class="bi-chevron-double-right"></i></button>
                    </div>
                    <small>di {{ visits.last_page }} pagine - {{ visits.total ? visits.total : 0 }} risultati</small>
                  </div>



                </div>


              </div>
              <div class="alert alert-info" v-else>
                Nessuna visita da mostrare. Selezionare un codice agente nella colonna di sinistra.
              </div>
            </div>
            <!-- DOCUMENTS LIST | END-->



          </div>
        </div>

      
        
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import CmMixin from '../mixins/CmMixin'
import ProfileBadge from '../components/ProfileBadge'

export default {
  mixins: [ CmMixin ],
  components: { ProfileBadge },
  name: 'ProfileVisits',
  data: () => {
		return {
      selected_profile: {},
    
      search: '',
      date_start: '',
      date_end: '',
			visits: {},


		}
	},
  mounted: function(){

    var self = this;

    /*
    if( this.user && this.user.role == 'admin' ){
      this.adminGetUsers();
    }
    */


    if( this.current_customer )
      this.search = this.current_customer.external_id;

    
      if( self.user.role == 'admin' ){
        self.adminGetUsers( function(){

          if( self.current_profile ){
            self.selected_profile = self.current_profile;
            self.getProfileVisits({ search: self.search });  
          }
          else{
            self.getProfileVisits();
          }
          
        });
      }
      else{
        
        if( self.current_profile ){
          self.selected_profile = self.current_profile;
          self.getProfileVisits({ search: self.search });
        }
        else{
          self.getProfileVisits();
        }

      }
      
    

    
    
  },
  methods:{

    getProfileVisits: function( post_data ){
      var self = this;

      
      if( !post_data)
        post_data = {};
      

      var external_id = this.selected_profile.external_id ? this.selected_profile.external_id : '';
    
      this.api.post( 'visit/index' + (external_id ? '/'+external_id : '') , post_data)
      .then(function (response) {

          if(response.data.visits){
            self.notification.success( 'Visite caricati correttamente' );
            self.visits = response.data.visits;
            // self.$set(self, 'visits', response.data.visits);
          }
      })
    },

   

  },
  computed:{
    visits_filtered: function(){
      return this.visits.data;
    }
  },
  watch: {
    selected_profile: function( new_val){
      this.getProfileVisits();
    }
  }
  
  
}
</script>
